var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        isDone: _vm.isDone,
        title: _vm.header.title,
        description: _vm.header.description,
        image: _vm.header.image,
        debug: _vm.header.debug
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pt-4",
          attrs: {
            flat: "",
            "max-width": "500"
          }
        },
        [
          _c("report-image-upload", {
            attrs: {
              asset: _vm.asset,
              example: _vm.example,
              instruction: _vm.instruction,
              type: _vm.type
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }