var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      key: "preference" + _vm.key,
      attrs: {
        margin: 0,
        flat: "",
        outlined: "",
        hideTitle: true,
        subtitle: _vm.$t(
          "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.datePreference"
        )
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.showRemove
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        "data-test-date-add-btn": ""
                      },
                      on: {
                        click: _vm.add
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                  )
                : _vm._e(),
              _vm.showRemove
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        "data-test-date-close-btn": ""
                      },
                      on: {
                        click: _vm.remove
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.showRemove
        ? _c(
            "div",
            {
              staticClass: "pt-2 pr-2 pl-2"
            },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      flat: "",
                      "full-width": "",
                      color: "primary"
                    },
                    model: {
                      value: _vm.datePreference,
                      callback: function callback($$v) {
                        _vm.datePreference = $$v
                      },
                      expression: "datePreference"
                    }
                  }),
                  _c("v-text-field", {
                    key: "date-field" + _vm.key,
                    attrs: {
                      outlined: "",
                      type: "date",
                      readonly: ""
                    },
                    model: {
                      value: _vm.datePreference,
                      callback: function callback($$v) {
                        _vm.datePreference = $$v
                      },
                      expression: "datePreference"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }