var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-hover", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function fn(_ref) {
            var hover = _ref.hover
            return [
              _c(
                "v-list-item",
                _vm._g(
                  {
                    style: _vm.item.ack ? "opacity: 0.6;" : ""
                  },
                  _vm.attachEventListener
                ),
                [
                  _vm._t("icon"),
                  _vm.hasActionListener()
                    ? _c(
                        "v-list-item-action",
                        [
                          !_vm.item.ack
                            ? _c("v-checkbox", {
                                attrs: {
                                  "input-value": false,
                                  disabled: false
                                },
                                on: {
                                  click: _vm.action
                                }
                              })
                            : _vm.item.ack
                            ? _c(
                                "tooltip",
                                {
                                  attrs: {
                                    text: _vm.confirmationText
                                  }
                                },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      "input-value": true,
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "text-xs"
                        },
                        [
                          _vm.showIcons
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    "x-small": ""
                                  }
                                },
                                [_vm._v("mdi-calendar-blank")]
                              )
                            : _vm._e(),
                          _vm.item.isOverdue
                            ? _c(
                                "span",
                                {
                                  staticClass: "red--text"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("overdueSince", {
                                          date: _vm.detailedDateWithDay(
                                            _vm.item.start
                                          )
                                        })
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("detailedDateWithDay")(
                                      _vm.item.start
                                    )
                                  )
                                )
                              ]),
                          !_vm.item.isAllDay
                            ? _c("span", [
                                _vm._v(
                                  "| " +
                                    _vm._s(
                                      _vm._f("formatHoursAndMinutes")(
                                        new Date(_vm.item.start)
                                      )
                                    ) +
                                    " Uhr"
                                )
                              ])
                            : _vm._e(),
                          !_vm.item.isAllDay && _vm.item.end
                            ? _c("span", [
                                _vm._v(
                                  " - " +
                                    _vm._s(
                                      _vm._f("formatHoursAndMinutes")(
                                        new Date(_vm.item.end)
                                      )
                                    ) +
                                    " Uhr"
                                )
                              ])
                            : _vm._e(),
                          _vm.item.recurringEventId
                            ? _c(
                                "tooltip",
                                {
                                  attrs: {
                                    text: _vm.$t(
                                      "views.fleet.EventCreateDialog.recurrence"
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "x-small": ""
                                      }
                                    },
                                    [_vm._v("mdi-autorenew")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "text-truncate",
                          class: _vm.item.ack
                            ? "text-decoration-line-through"
                            : ""
                        },
                        [
                          _vm.hasOpenDetailListener()
                            ? _c("a", {
                                domProps: {
                                  textContent: _vm._s(_vm.item.summary)
                                },
                                on: {
                                  click: function click($event) {
                                    $event.stopPropagation()
                                    return _vm.emitOpenDetail.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.item.summary)
                                }
                              })
                        ]
                      ),
                      !_vm.small && _vm.item.description
                        ? _c(
                            "v-list-item-subtitle",
                            {
                              staticClass: "text-truncate text-xs"
                            },
                            [_vm._v(" " + _vm._s(_vm.item.description) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "text-truncate text-xs"
                        },
                        [
                          _vm.small && _vm.vehicle
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "x-small": ""
                                      }
                                    },
                                    [_vm._v("mdi-car")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.vehicle.displayName ||
                                          _vm.vehicle.numberplate ||
                                          _vm.vehicle.id
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.item.location
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: ""
                                      }
                                    },
                                    [_vm._v("mdi-map-marker")]
                                  ),
                                  _vm._v(" " + _vm._s(_vm.item.location) + " ")
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.item.rrule && _vm.isDebug
                        ? _c("v-list-item-subtitle", [
                            _vm._v(
                              " " + _vm._s(_vm.recurrenceDescription) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.small && _vm.vehicle
                        ? _c("refs-vehicle", {
                            staticClass: "ml-n4",
                            attrs: {
                              item: _vm.vehicle
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.confirmable
                    ? _c(
                        "v-list-item-action",
                        {
                          staticClass: "mx-0 px-0"
                        },
                        [
                          _vm.isConfirmable
                            ? _c(
                                "tooltip",
                                {
                                  attrs: {
                                    text: _vm.$t(
                                      "common.BackendResourceEnum.click.tooltip.event"
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      hover || _vm.confirmablePersistant
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                loading: _vm.item.loading,
                                                text: "",
                                                color: "success",
                                                elevation: "0",
                                                small: ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  $event.stopPropagation()
                                                  return _vm.confirm.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "common.BackendResourceEnum.click.event"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "pl-1",
                                                  attrs: {
                                                    small: ""
                                                  }
                                                },
                                                [_vm._v(" mdi-check ")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("event-card-acknowledgement-dialog", {
                            ref: "eventCardAcknowledgementDialog",
                            attrs: {
                              value: _vm.item
                            },
                            on: {
                              confirmed: function confirmed() {
                                return _vm.$emit("confirm", _vm.item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }