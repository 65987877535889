var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        supressKeyboardConfirm: true,
        isDialogActive: _vm.isDialogActive,
        title: _vm.$t(
          "components.partner.PartnerReportDetail.SendToDaDialog.title"
        ),
        hideLeft: _vm.hideLeft,
        hideRight: _vm.hideRight
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        close: _vm.close,
        leftClick: function leftClick($event) {
          _vm.window = _vm.WindowOptions.SEARCH
        },
        rightClick: _vm.rightClick
      }
    },
    [
      _c(
        "v-window",
        {
          model: {
            value: _vm.window,
            callback: function callback($$v) {
              _vm.window = $$v
            },
            expression: "window"
          }
        },
        [
          _c(
            "v-window-item",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            row: ""
                          },
                          model: {
                            value: _vm.searchOption,
                            callback: function callback($$v) {
                              _vm.searchOption = $$v
                            },
                            expression: "searchOption"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.numberplate"
                              ),
                              value: _vm.SearchOptions.NUMBERPLATE
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.jobId"
                              ),
                              value: _vm.SearchOptions.DOSSIER_ID
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.search"
                          ),
                          outlined: "",
                          "append-icon": "mdi-magnify",
                          loading: _vm.isLoadingDaServiceEvents
                        },
                        on: {
                          "click:append": _vm.fetchDossiers,
                          keydown: function keydown($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.fetchDossiers.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.search,
                          callback: function callback($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-card-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.titleExisting"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _vm.daServiceEvents && _vm.daServiceEvents.length > 0
                        ? _c(
                            "v-list",
                            [
                              _vm._l(this.daServiceEvents, function(
                                item,
                                index
                              ) {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: ""
                                        .concat(item.serviceEventId, "+")
                                        .concat(index),
                                      on: {
                                        click: function click($event) {
                                          return _vm.selectDossier(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c("v-icon", [_vm._v("mdi-calendar")])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.firstName) +
                                                " " +
                                                _vm._s(item.lastName) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.numberPlate) +
                                                " " +
                                                _vm._s(item.email) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.serviceEventId) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        : !_vm.isLoadingDaServiceEvents
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: _vm.switchToNew
                              }
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.partner.PartnerReportDetail.SendToKsrDialog.titleRequestNew"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.partner.PartnerReportDetail.SendToKsrDialog.jobSearchingNotFound",
                                            {
                                              search: _vm.search
                                            }
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoadingDaServiceEvents
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "list-item-avatar"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider", {
                staticClass: "mt-2"
              }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-card-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.tooltipRequestNew"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: _vm.switchToNew
                          }
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.partner.PartnerReportDetail.SendToKsrDialog.titleRequestNew"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-window-item",
            [
              _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          disabled: _vm.resourcesLoading,
                          loading: _vm.resourcesLoading,
                          items: _vm.daResources,
                          clearable: "",
                          outlined: "",
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToDaDialog.resource"
                          ),
                          "item-text": "name",
                          "item-value": "id"
                        },
                        on: {
                          input: _vm.fetchFreeSlots
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function fn(data) {
                              return [
                                data.item
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(data.item.name) +
                                          " (" +
                                          _vm._s(data.item.id) +
                                          ") "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function fn(data) {
                              return [
                                [
                                  data.item
                                    ? _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              innerHTML: _vm._s(data.item.name)
                                            }
                                          }),
                                          _c("v-list-item-subtitle", {
                                            domProps: {
                                              innerHTML: _vm._s(data.item.id)
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectionResourceId,
                          callback: function callback($$v) {
                            _vm.selectionResourceId = $$v
                          },
                          expression: "selectionResourceId"
                        }
                      }),
                      _c("v-autocomplete", {
                        attrs: {
                          disabled: _vm.eventTypesLoading,
                          loading: _vm.eventTypesLoading,
                          items: _vm.daEventTypes,
                          clearable: "",
                          outlined: "",
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToDaDialog.eventType"
                          ),
                          "item-text": "name",
                          "item-value": "id"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function fn(data) {
                              return [
                                data.item
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.name) +
                                          " (" +
                                          _vm._s(data.item.id) +
                                          ") "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function fn(data) {
                              return [
                                [
                                  data.item
                                    ? _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              innerHTML: _vm._s(data.item.name)
                                            }
                                          }),
                                          _c("v-list-item-subtitle", {
                                            domProps: {
                                              innerHTML: _vm._s(data.item.id)
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectionEventTypeId,
                          callback: function callback($$v) {
                            _vm.selectionEventTypeId = $$v
                          },
                          expression: "selectionEventTypeId"
                        }
                      }),
                      _vm.daFreeSlots || _vm.freeSlotLoading
                        ? _c("v-autocomplete", {
                            attrs: {
                              disabled: _vm.freeSlotLoading,
                              loading: _vm.freeSlotLoading,
                              items: _vm.daFreeSlots,
                              clearable: "",
                              outlined: "",
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.SendToDaDialog.freeSlot"
                              ),
                              "item-text": "display",
                              "item-value": "value"
                            },
                            model: {
                              value: _vm.selectionFreeSlot,
                              callback: function callback($$v) {
                                _vm.selectionFreeSlot = $$v
                              },
                              expression: "selectionFreeSlot"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-window-item",
            [
              _vm.selectedServiceEvent
                ? _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-icon", [_vm._v("mdi-calendar")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.selectedServiceEvent.firstName
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.selectedServiceEvent.lastName
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.selectedServiceEvent.numberPlate
                                        ) +
                                        " " +
                                        _vm._s(_vm.selectedServiceEvent.email) +
                                        " "
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.selectedServiceEvent
                                            .serviceEventId
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              disabled: _vm.workshopServicesLoading,
                              loading: _vm.workshopServicesLoading,
                              items: _vm.daWorkshopServices,
                              clearable: "",
                              outlined: "",
                              label: _vm.$t(
                                "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.workshopService"
                              ),
                              "item-text": "name",
                              "item-value": "id"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function fn(data) {
                                    return [
                                      data.item
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.name) +
                                                " (" +
                                                _vm._s(data.item.id) +
                                                ") "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function fn(data) {
                                    return [
                                      [
                                        data.item
                                          ? _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.name
                                                    )
                                                  }
                                                }),
                                                _c("v-list-item-subtitle", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.id
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3035213167
                            ),
                            model: {
                              value: _vm.selectionWorkshopServiceId,
                              callback: function callback($$v) {
                                _vm.selectionWorkshopServiceId = $$v
                              },
                              expression: "selectionWorkshopServiceId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }