var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      key: "contact" + _vm.key
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.isValid,
            callback: function callback($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _vm._l(_vm.reportDto.insurances || [], function(insurance, index) {
            return _c(
              "card",
              {
                key: "insurance" + index,
                staticClass: "mb-1",
                attrs: {
                  margin: 0,
                  flat: "",
                  outlined: true,
                  hideTitle: true,
                  subtitle:
                    _vm.$t(
                      "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.title.insurance"
                    ) +
                    " " +
                    (index + 1)
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions",
                      fn: function fn() {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                "data-test-insurance-close-btn": ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.remove(index)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "pt-2 pr-2 pl-2"
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12",
                              md: "6"
                            }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                label: _vm.$t("report.insurance.name"),
                                rules: _vm.requiredRule
                              },
                              model: {
                                value: insurance.name,
                                callback: function callback($$v) {
                                  _vm.$set(insurance, "name", $$v)
                                },
                                expression: "insurance.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12",
                              md: "6"
                            }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                label: _vm.$t("report.insurance.number")
                              },
                              model: {
                                value: insurance.number,
                                callback: function callback($$v) {
                                  _vm.$set(insurance, "number", $$v)
                                },
                                expression: "insurance.number"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12"
                            }
                          },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                flat: "",
                                outlined: "",
                                label: _vm.$t("report.insurance.type"),
                                items: _vm.insuranceTypeItems,
                                clearable: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function fn(_ref) {
                                      var item = _ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "report.insurance.types.".concat(
                                                  item
                                                )
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "selection",
                                    fn: function fn(_ref2) {
                                      var item = _ref2.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "report.insurance.types.".concat(
                                                  item
                                                )
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: insurance.type,
                                callback: function callback($$v) {
                                  _vm.$set(insurance, "type", $$v)
                                },
                                expression: "insurance.type"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          }),
          _c("card", {
            key: "contact" + _vm.key,
            attrs: {
              margin: 0,
              flat: "",
              outlined: "",
              hideTitle: true,
              subtitle: _vm.$t(
                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.title.insurance"
              )
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function fn() {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          text: "",
                          "data-test-insurance-add-btn": ""
                        },
                        on: {
                          click: _vm.add
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }