var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.COST
          }
        },
        [
          !_vm.hideButton
            ? _vm._t(
                "activator",
                function() {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          elevation: 0
                        },
                        on: {
                          click: _vm.show
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]
                    )
                  ]
                },
                {
                  on: _vm.show
                }
              )
            : _vm._e()
        ],
        2
      ),
      _vm.isDialogActiveLocal
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                title: _vm.$t("cost.createCostGroup"),
                isDialogActive: _vm.isDialogActiveLocal,
                rightText: _vm.$t("create"),
                loading: _vm.isLoading,
                rightLoading: _vm.isLoading,
                rightDisabled: !_vm.costGroup.title,
                supressKeyboardConfirm: true
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDialogActiveLocal = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDialogActiveLocal = $event
                },
                leftClick: function leftClick($event) {
                  _vm.isDialogActive = false
                },
                close: function close($event) {
                  _vm.isDialogActive = false
                },
                rightClick: _vm.onRightClick
              }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "pt-3",
                  attrs: {
                    "no-gutters": ""
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: _vm.$t("objects.costGroup.title"),
                          rules: _vm.requiredRule,
                          id: "titleInput"
                        },
                        model: {
                          value: _vm.costGroup.title,
                          callback: function callback($$v) {
                            _vm.$set(_vm.costGroup, "title", $$v)
                          },
                          expression: "costGroup.title"
                        }
                      }),
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          label: _vm.$t("objects.costGroup.description")
                        },
                        model: {
                          value: _vm.costGroup.description,
                          callback: function callback($$v) {
                            _vm.$set(_vm.costGroup, "description", $$v)
                          },
                          expression: "costGroup.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ma-n3"
                        },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("views.CustomFieldList.customFields")
                                ) +
                                " "
                            )
                          ]),
                          _c("custom-field-configuration-form", {
                            staticClass: "mx-2 my-n3",
                            model: {
                              value:
                                _vm.costGroup.configuration.customFieldConfig,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.costGroup.configuration,
                                  "customFieldConfig",
                                  $$v
                                )
                              },
                              expression:
                                "costGroup.configuration.customFieldConfig"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }