var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        loading: _vm.rightLoading || _vm.isLoadingSharedContents,
        isDialogActive: _vm.isShareDialogActive,
        title: _vm.$t("share"),
        subtitle: _vm.$t("nav.SharedContentView.description"),
        rightLoading: _vm.rightLoading,
        hideLeft: _vm.step === 4,
        leftText: _vm.leftText,
        rightText: _vm.rightText
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isShareDialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isShareDialogActive = $event
        },
        leftClick: _vm.leftClick,
        rightClick: _vm.rightClick
      }
    },
    [
      _vm.step === 0
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _vm.isLoadingSharedContents
                ? _c("v-skeleton-loader", {
                    attrs: {
                      type: "image"
                    }
                  })
                : _vm.sharings.length
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "overflow-y": "auto",
                        "max-height": "300px"
                      }
                    },
                    _vm._l(_vm.sharings, function(sharing) {
                      return _c(
                        "v-list-item",
                        {
                          key: "sharing" + sharing.id
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(sharing.title) + " ")
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(sharing.description) + " ")
                              ]),
                              sharing.validBy
                                ? _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("simpleDoubleDigitDate")(
                                            sharing.validBy
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    elevation: 0
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.copyUrlFromSharing(sharing)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "timeLine.ActivityTimeLineItemShare.btnText"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.step === 1
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("v-switch", {
                    attrs: {
                      flat: "",
                      label: _vm.isExpiring
                        ? _vm.$t(
                            "timeLine.ReportActivityBoxShareComponent.expiringLink"
                          )
                        : _vm.$t(
                            "timeLine.ReportActivityBoxShareComponent.nonExpiringLink"
                          )
                    },
                    model: {
                      value: _vm.isExpiring,
                      callback: function callback($$v) {
                        _vm.isExpiring = $$v
                      },
                      expression: "isExpiring"
                    }
                  }),
                  _vm.isExpiring
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("objects.sharedContent.validBy")) +
                            " " +
                            _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.validBy))
                        )
                      ])
                    : _vm._e(),
                  _vm.isExpiring
                    ? _c("v-date-picker", {
                        attrs: {
                          outlined: "",
                          "full-width": ""
                        },
                        model: {
                          value: _vm.validBy,
                          callback: function callback($$v) {
                            _vm.validBy = $$v
                          },
                          expression: "validBy"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-information")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-subtitle",
                            {
                              staticClass: "text-wrap"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "timeLine.ReportActivityBoxShareComponent.title"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.step === 2
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _vm.sharedContent
                ? _c(
                    "v-list-item",
                    {
                      key: "shared"
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(_vm.sharedContent.title) + " ")
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " + _vm._s(_vm.sharedContent.description) + " "
                            )
                          ]),
                          _vm.sharedContent.validBy
                            ? _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("simpleDoubleDigitDate")(
                                        _vm.sharedContent.validBy
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                elevation: 0
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.copyUrlFromSharing(
                                    _vm.sharedContent
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "timeLine.ActivityTimeLineItemShare.btnText"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.step === 3
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("template-card", {
                ref: "templateCard",
                attrs: {
                  partnerId: _vm.partnerId,
                  to: [],
                  from: [_vm.partner],
                  context: {
                    url: {
                      sharing: _vm.sharedContent.url
                    }
                  }
                },
                on: {
                  sent: function sent($event) {
                    _vm.step++
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }