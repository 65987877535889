import { render, staticRenderFns } from "./MDetailViewGrid.vue?vue&type=template&id=9e446da2&scoped=true&"
import script from "./MDetailViewGrid.vue?vue&type=script&lang=ts&"
export * from "./MDetailViewGrid.vue?vue&type=script&lang=ts&"
import style0 from "./MDetailViewGrid.vue?vue&type=style&index=0&id=9e446da2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e446da2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBottomSheet,VBtn,VCard,VCol,VIcon,VRow})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Touch})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9e446da2')) {
      api.createRecord('9e446da2', component.options)
    } else {
      api.reload('9e446da2', component.options)
    }
    module.hot.accept("./MDetailViewGrid.vue?vue&type=template&id=9e446da2&scoped=true&", function () {
      api.rerender('9e446da2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/utility/mmmint/MDetailViewGrid.vue"
export default component.exports