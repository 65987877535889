import { render, staticRenderFns } from "./ReportAccidentDetails.vue?vue&type=template&id=24092dbe&scoped=true&"
import script from "./ReportAccidentDetails.vue?vue&type=script&lang=ts&"
export * from "./ReportAccidentDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ReportAccidentDetails.vue?vue&type=style&index=0&id=24092dbe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24092dbe",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VAutocomplete,VCard,VDatePicker,VForm,VMenu,VRadio,VRadioGroup,VTextField,VTextarea,VTimePicker})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24092dbe')) {
      api.createRecord('24092dbe', component.options)
    } else {
      api.reload('24092dbe', component.options)
    }
    module.hot.accept("./ReportAccidentDetails.vue?vue&type=template&id=24092dbe&scoped=true&", function () {
      api.rerender('24092dbe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/report/ReportAccidentDetails.vue"
export default component.exports