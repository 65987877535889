var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        isDone: _vm.isDone,
        title: _vm.header.title,
        description: _vm.header.description,
        debug: _vm.damageLocation
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pt-4",
          attrs: {
            flat: "",
            "max-width": "500"
          }
        },
        [
          _c("form", [
            _c(
              "div",
              {
                staticClass: "center"
              },
              [
                _c("damage-locator-truck", {
                  attrs: {
                    "damage-locations": _vm.damageLocation,
                    clickedColor: _vm.color,
                    readOnly: false
                  },
                  on: {
                    svgClicked: _vm.svgClicked
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "v-card-text",
            {
              staticClass: "pb-0"
            },
            [
              _c(
                "transition",
                {
                  attrs: {
                    name: "fade"
                  }
                },
                [
                  !_vm.isDone
                    ? _c(
                        "p",
                        {
                          staticClass: "pb-0 mb-0"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("report.damageLocator.locateDamage"))
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c("Debug", {
            attrs: {
              debug: _vm.damageLocation
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }