var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "div",
        [
          _vm.report.vehicleId
            ? _c(
                "v-timeline-item",
                {
                  attrs: {
                    small: "",
                    icon: "mdi-car",
                    "fill-dot": ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mb-1",
                      staticStyle: {
                        display: "flex"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text-caption"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.nouns.vehicle")) + " "
                          )
                        ]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: {
                            "x-small": "",
                            elevation: 0,
                            color: "info"
                          },
                          on: {
                            click: _vm.changeVehicleReference
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.verbs.edit")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.report.vehicleId
            ? _c(
                "v-timeline-item",
                {
                  attrs: {
                    "hide-dot": ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mx-n4 mt-n5"
                    },
                    [
                      _vm.loadingVehicle
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "list-item"
                            }
                          })
                        : _vm.vehicle
                        ? _c("refs-vehicle", {
                            attrs: {
                              item: _vm.vehicle
                            },
                            on: {
                              openDetail: _vm.openVehicleDetail
                            }
                          })
                        : !_vm.vehicle
                        ? _c("latest-entries-card-empty", {
                            attrs: {
                              item: _vm.vehicle
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm.isFleetPartner
            ? _c(
                "v-alert",
                {
                  staticClass: "my-4 d-print-none",
                  attrs: {
                    type: "info",
                    dense: "",
                    icon: "mdi-car"
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        align: "center"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "grow"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "timeLine.VehicleTimeLineElement.suggestion"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "shrink"
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                text: ""
                              },
                              on: {
                                click: _vm.changeVehicleReference
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "timeLine.VehicleTimeLineElement.connect"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            title:
              _vm.isSelectionMade && !_vm.selected
                ? _vm.$t(
                    "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.removeVehicleReference"
                  )
                : _vm.$t("timeLine.VehicleTimeLineElement.chooseVehicle"),
            subtitle:
              _vm.isSelectionMade && _vm.selected
                ? _vm.$t(
                    "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.confirmSelection"
                  )
                : "",
            isDialogActive: _vm.isUpdateVehicleDialog,
            rightLoading: _vm.loadingUpdate,
            hideRight: !_vm.isSelectionMade,
            hideLeft: !_vm.isSelectionMade,
            supressKeyboardActions: true,
            leftText: _vm.$t("back")
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isUpdateVehicleDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isUpdateVehicleDialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.isSelectionMade = false
            },
            rightClick: _vm.confirm,
            close: function close($event) {
              _vm.isUpdateVehicleDialog = false
            }
          }
        },
        [
          !_vm.isSelectionMade
            ? _c(
                "div",
                [
                  _vm.isLoadingVehicleIdUpdate
                    ? _c("v-progress-linear", {
                        attrs: {
                          indeterminate: ""
                        }
                      })
                    : _vm._e(),
                  _c("v-card-subtitle", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.changeVehicleHint"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("vehicle-api-selection", {
                    ref: "vehicle-api-selection",
                    staticClass: "mb-n6",
                    attrs: {
                      clearable: true
                    },
                    on: {
                      change: _vm.select
                    }
                  }),
                  _vm._l(_vm.vehicleSuggestions, function(vehicleSuggestion) {
                    return _c(
                      "v-card",
                      {
                        key: vehicleSuggestion.id,
                        staticClass: "my-2",
                        staticStyle: {
                          cursor: "pointer"
                        },
                        attrs: {
                          outlined: ""
                        },
                        on: {
                          click: function click($event) {
                            return _vm.select(vehicleSuggestion)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("refs-vehicle", {
                              attrs: {
                                item: vehicleSuggestion
                              },
                              on: {
                                openDetail: _vm.openVehicleDetail
                              }
                            }),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  text: ""
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.select(vehicleSuggestion)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.VehicleTimeLineElement.connect"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _vm.isFleetPartner
                    ? _c(
                        "Can",
                        {
                          attrs: {
                            I: _vm.ActionEnum.CREATE,
                            a: _vm.ResourceEnum.VEHICLE
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                outlined: "",
                                block: "",
                                elevation: 0
                              },
                              on: {
                                click: _vm.createNewVehicle
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "timeLine.VehicleTimeLineElement.createNewVehicle"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _c(
                "div",
                [
                  _vm.selected
                    ? _c("refs-vehicle", {
                        attrs: {
                          item: _vm.selected
                        }
                      })
                    : _vm._e()
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }