var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.getFirstAssignee
    ? _c(
        "tooltip",
        {
          attrs: {
            text: _vm.toolTipContent
          }
        },
        [
          _vm.assignees.length > 1
            ? _c(
                "v-badge",
                {
                  attrs: {
                    content: _vm.assignees.length,
                    bottom: "",
                    overlap: "",
                    color: "grey darken-1"
                  }
                },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: {
                        color: "info",
                        size: "25"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text-xs white--text"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getUserInitials(_vm.getFirstAssignee)
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            : _c(
                "v-avatar",
                {
                  attrs: {
                    color: "info",
                    size: "25"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-xs white--text"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getUserInitials(_vm.getFirstAssignee)) +
                          " "
                      )
                    ]
                  )
                ]
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }