var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            top: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      {
                        staticClass: "mr-2",
                        attrs: {
                          small: ""
                        }
                      },
                      on
                    ),
                    [_vm._v(_vm._s(_vm.icon))]
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
      ),
      _vm._v(" " + _vm._s(_vm.displayName) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }