var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      ref: "form",
      model: {
        value: _vm.valid,
        callback: function callback($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          outlined: "",
          rules: _vm.nameRule,
          required: _vm.required,
          label:
            _vm.$t("report.witness.firstName") + (_vm.required ? " *" : ""),
          "data-test-witness-first-name": ""
        },
        model: {
          value: _vm.witness.firstName,
          callback: function callback($$v) {
            _vm.$set(_vm.witness, "firstName", $$v)
          },
          expression: "witness.firstName"
        }
      }),
      _c("v-text-field", {
        attrs: {
          outlined: "",
          rules: _vm.nameRule,
          required: _vm.required,
          label: _vm.$t("report.witness.lastName") + (_vm.required ? " *" : ""),
          "data-test-witness-last-name": ""
        },
        model: {
          value: _vm.witness.lastName,
          callback: function callback($$v) {
            _vm.$set(_vm.witness, "lastName", $$v)
          },
          expression: "witness.lastName"
        }
      }),
      _c("v-text-field", {
        attrs: {
          outlined: "",
          rules: _vm.phoneNumberRules,
          required: _vm.required,
          label: _vm.$t("report.witness.phone") + (_vm.required ? " *" : ""),
          "data-test-witness-phone": ""
        },
        model: {
          value: _vm.witness.contact.phone,
          callback: function callback($$v) {
            _vm.$set(_vm.witness.contact, "phone", $$v)
          },
          expression: "witness.contact.phone"
        }
      }),
      _c("v-text-field", {
        attrs: {
          outlined: "",
          rules: _vm.emailRules,
          required: _vm.required,
          label: _vm.$t("report.witness.email") + (_vm.required ? " *" : ""),
          "data-test-witness-mail": ""
        },
        model: {
          value: _vm.witness.contact.email,
          callback: function callback($$v) {
            _vm.$set(_vm.witness.contact, "email", $$v)
          },
          expression: "witness.contact.email"
        }
      }),
      !_vm.role
        ? _c("v-autocomplete", {
            attrs: {
              rules: _vm.roleRules,
              outlined: "",
              required: _vm.required,
              label: _vm.$t("report.witness.role") + (_vm.required ? " *" : ""),
              items: _vm.witnessRolesItems,
              "data-test-witness-role": ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("report.witness.roles.".concat(item))) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "selection",
                  fn: function fn(_ref2) {
                    var item = _ref2.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("report.witness.roles.".concat(item))) +
                          " "
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              911662620
            ),
            model: {
              value: _vm.witness.role,
              callback: function callback($$v) {
                _vm.$set(_vm.witness, "role", $$v)
              },
              expression: "witness.role"
            }
          })
        : _vm._e(),
      _vm.showInsurance
        ? _c("v-checkbox", {
            attrs: {
              label: _vm.$t("report.witness.insurance")
            },
            model: {
              value: _vm.hasInsurance,
              callback: function callback($$v) {
                _vm.hasInsurance = $$v
              },
              expression: "hasInsurance"
            }
          })
        : _vm._e(),
      _vm.hasInsurance
        ? _c("insurance-form", {
            attrs: {
              types: _vm.insuranceTypes,
              type: _vm.type
            },
            model: {
              value: _vm.witness.insurances[0],
              callback: function callback($$v) {
                _vm.$set(_vm.witness.insurances, 0, $$v)
              },
              expression: "witness.insurances[0]"
            }
          })
        : _vm._e(),
      _vm.numberplate
        ? _c("v-text-field", {
            attrs: {
              outlined: "",
              label: _vm.$t("report.witness.numberplate"),
              rules: _vm.numberplateRules,
              "data-test-witness-numberplate": ""
            },
            model: {
              value: _vm.witness.numberplate,
              callback: function callback($$v) {
                _vm.$set(_vm.witness, "numberplate", $$v)
              },
              expression: "witness.numberplate"
            }
          })
        : _vm._e(),
      _vm.showInjury
        ? _c("v-checkbox", {
            attrs: {
              label: _vm.$t("report.witness.isInjured")
            },
            model: {
              value: _vm.witness.isInjured,
              callback: function callback($$v) {
                _vm.$set(_vm.witness, "isInjured", $$v)
              },
              expression: "witness.isInjured"
            }
          })
        : _vm._e(),
      _vm.witness.isInjured
        ? _c("v-textarea", {
            attrs: {
              outlined: "",
              required: _vm.required,
              label:
                _vm.$t("report.witness.isInjuredDetail") +
                (_vm.required ? " *" : ""),
              rules: _vm.noteRule
            },
            model: {
              value: _vm.witness.note,
              callback: function callback($$v) {
                _vm.$set(_vm.witness, "note", $$v)
              },
              expression: "witness.note"
            }
          })
        : _vm._e(),
      _vm.showLicense
        ? _c("v-card-text", [
            _vm._v(" " + _vm._s(_vm.$t("report.witness.appendix")) + " ")
          ])
        : _vm._e(),
      _vm.showLicense
        ? _c("report-image-upload", {
            attrs: {
              asset: _vm.assetFront,
              example: _vm.exampleFront,
              instruction: _vm.instruction,
              type: _vm.typeFront,
              imageIds: _vm.witness.imageIds,
              "data-test-image": ""
            },
            on: {
              onImageUpload: function onImageUpload(image) {
                return _vm.onImageUpload(image)
              },
              onImageDeleted: function onImageDeleted(image) {
                return _vm.onImageDeleted(image)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }