var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        margin: 0,
        flat: "",
        outlined: "",
        hideTitle: true,
        subtitle: _vm.$t(
          "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.identifier"
        )
      }
    },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.isValid,
            callback: function callback($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-2 pr-2 pl-2"
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.READ,
                        a: _vm.ResourceEnum.VEHICLE,
                        field: _vm.partnerId
                      }
                    },
                    [
                      _vm.showVehicleSelection
                        ? _c("vehicle-api-selection", {
                            ref: "vehicle-api-selection",
                            attrs: {
                              hint: _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.changeVehicleHint"
                              ),
                              persistentHint: true,
                              loading:
                                _vm.loadingVehicle ||
                                _vm.loadingVehicleOnChange,
                              "data-test-vehicle-search": ""
                            },
                            on: {
                              change: _vm.onVehicleSelect
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showNumberPlate
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("numberplate-form", {
                        ref: "numberplateForm",
                        attrs: {
                          valid: _vm.isValid,
                          countryCode: _vm.countryCode
                        },
                        on: {
                          valid: function valid(v) {
                            return (_vm.isValid = v)
                          }
                        },
                        model: {
                          value: _vm.reportDto.numberplate,
                          callback: function callback($$v) {
                            _vm.$set(_vm.reportDto, "numberplate", $$v)
                          },
                          expression: "reportDto.numberplate"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.externalId"
                      ),
                      flat: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.reportDto.externalId,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto, "externalId", $$v)
                      },
                      expression: "reportDto.externalId"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }