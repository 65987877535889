var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "timeline-card",
    {
      attrs: {
        title: _vm.title
      },
      scopedSlots: _vm._u(
        [
          _vm.canUpdateReport
            ? {
                key: "menu",
                fn: function fn() {
                  return [
                    _c("partner-report-update-report-dialog", {
                      attrs: {
                        config: [_vm.ReportUpdateTabEnum.witness],
                        activityTarget: "witness"
                      }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              dense: "",
              "item-key": "name",
              headers: _vm.headers,
              items: _vm.witnesses,
              "hide-default-footer": "",
              "disable-pagination": ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.role",
                fn: function fn(_ref) {
                  var item = _ref.item
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("report.witness.roles.".concat(item.role))
                        ) + " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.insurances",
                fn: function fn(_ref2) {
                  var item = _ref2.item
                  return _vm._l(item.insurances, function(insurance, i) {
                    return _c(
                      "div",
                      {
                        key: "insurance" + i
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(insurance.name) + " ")
                        ]),
                        _c("span", [
                          _vm._v(" " + _vm._s(insurance.number) + " ")
                        ]),
                        insurance.type
                          ? _c("span", [
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm.$t(
                                      "report.insurance.types.".concat(
                                        insurance.type
                                      )
                                    )
                                  ) +
                                  ")"
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  })
                }
              },
              {
                key: "item.imageIds",
                fn: function fn(_ref3) {
                  var item = _ref3.item
                  return _vm._l(item.imageIds, function(imageId) {
                    return _c(
                      "span",
                      {
                        key: imageId
                      },
                      [
                        _vm.getImageForId(imageId)
                          ? _c(
                              "span",
                              [
                                _c(
                                  "partner-report-detail-info-witness-time-line-element-dialog",
                                  {
                                    attrs: {
                                      image: _vm.getImageForId(imageId)
                                    }
                                  }
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  })
                }
              },
              {
                key: "item.isInjured",
                fn: function fn(_ref4) {
                  var item = _ref4.item
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.isInjuredText(item)) + " ")])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }