var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.document
    ? _c(
        "timeline-card",
        {
          attrs: {
            id: _vm.document ? _vm.document.id : "document",
            loading: _vm.isLoading,
            title: _vm.title
          },
          scopedSlots: _vm._u(
            [
              {
                key: "menu",
                fn: function fn() {
                  return [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _c(
                          "context-menu",
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.CREATE,
                                      a: _vm.ResourceEnum.COST
                                    }
                                  },
                                  [
                                    _vm.document
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: _vm.createCost
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("cost.createCost")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm.document
                                  ? _c("file-download", {
                                      attrs: {
                                        downloadFiles: [_vm.downloadFile],
                                        listItem: true
                                      }
                                    })
                                  : _vm._e(),
                                _vm.activityLogEntry
                                  ? _c("activity-time-line-item-delete", {
                                      attrs: {
                                        text: true,
                                        partnerId:
                                          _vm.activityLogEntry.partnerId,
                                        activityId: _vm.activityLogEntry.id
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c(
                              "Can",
                              {
                                attrs: {
                                  I: _vm.ActionEnum.CREATE,
                                  a: _vm.ResourceEnum.COST
                                }
                              },
                              [
                                _vm.document
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-1",
                                        attrs: {
                                          elevation: "0",
                                          "x-small": "",
                                          dense: "",
                                          outlined: "",
                                          color: "info"
                                        },
                                        on: {
                                          click: _vm.createCost
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("cost.createCost")) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.document
                              ? _c("file-download", {
                                  attrs: {
                                    icon: false,
                                    outlined: true,
                                    downloadFiles: [_vm.downloadFile]
                                  }
                                })
                              : _vm._e(),
                            _vm.activityLogEntry
                              ? _c("activity-time-line-item-delete", {
                                  attrs: {
                                    partnerId: _vm.activityLogEntry.partnerId,
                                    activityId: _vm.activityLogEntry.id
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            559180188
          )
        },
        [
          _c("v-card-subtitle", [
            _vm._v(" " + _vm._s(_vm.document.title) + " ")
          ]),
          _vm.document.description
            ? _c(
                "v-card-subtitle",
                {
                  staticClass: "mt-n8"
                },
                [_vm._v(" " + _vm._s(_vm.document.description) + " ")]
              )
            : _vm._e(),
          _vm.document
            ? _c("activity-time-line-item-document-card-images", {
                attrs: {
                  document: _vm.document
                }
              })
            : _vm._e(),
          _c(
            "Can",
            {
              attrs: {
                I: _vm.ActionEnum.CREATE,
                a: _vm.ResourceEnum.COST
              }
            },
            [
              _c("cost-create-dialog", {
                ref: "costCreateDialog",
                attrs: {
                  isDialogActive: _vm.isCostCreateDialogActive,
                  hideButton: true,
                  partnerId: _vm.document.partnerId,
                  refs: [_vm.source]
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isCostCreateDialogActive = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isCostCreateDialogActive = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }