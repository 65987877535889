var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2",
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t("components.partner.PartnerRegistrationCard.title")
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c("registration-update-dialog", {
                attrs: {
                  loading: _vm.loading,
                  registration: _vm.registration,
                  registrationImageLink: _vm.registrationImageLink
                },
                on: {
                  update: _vm.update
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-card-text", [
        _c("small", [
          _vm._v(
            _vm._s(
              _vm.$t("components.partner.PartnerRegistrationCard.numberplate")
            )
          )
        ]),
        _c("br"),
        _vm.numberplate
          ? _c("span", [_vm._v(" " + _vm._s(_vm.numberplate))])
          : _vm._e(),
        _c("br"),
        !_vm.numberplate ? _c("span") : _vm._e(),
        _vm.registration.name
          ? _c("span", [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.partner.PartnerRegistrationCard.name")
                  )
                )
              ]),
              _c("br"),
              _c("span", [_vm._v(" " + _vm._s(_vm.registration.name) + " ")]),
              _c("br")
            ])
          : _vm._e(),
        _vm.registration.firstname
          ? _c("span", [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerRegistrationCard.firstname"
                    )
                  )
                )
              ]),
              _c("br"),
              _vm.registration.firstname
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.registration.firstname) + " ")
                  ])
                : _vm._e(),
              _c("br")
            ])
          : _vm._e(),
        _c("small", [
          _vm._v(
            _vm._s(
              _vm.$t(
                "components.partner.PartnerRegistrationCard.identificationnumber"
              )
            ) + " "
          )
        ]),
        _c("br"),
        _vm.registration.identificationnumber
          ? _c("span", [
              _vm._v(" " + _vm._s(_vm.registration.identificationnumber))
            ])
          : _vm._e(),
        _c("br"),
        _vm.displayManufacturerCode
          ? _c("span", [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerRegistrationCard.manufacturerNameCode"
                    )
                  )
                )
              ]),
              _c("br"),
              _vm.registration.manufacturerNameCode
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.registration.manufacturerNameCode))
                  ])
                : _vm._e(),
              _c("br")
            ])
          : _vm._e(),
        _vm.displayManufacturerTypeCode
          ? _c("span", [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerRegistrationCard.manufacturerTypeCode"
                    )
                  )
                )
              ]),
              _c("br"),
              _vm.registration.manufacturerTypeCode
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.registration.manufacturerTypeCode))
                  ])
                : _vm._e(),
              _c("br")
            ])
          : _vm._e(),
        _c("small", [
          _vm._v(
            _vm._s(
              _vm.$t(
                "components.partner.PartnerRegistrationCard.firstregistration"
              )
            )
          )
        ]),
        _c("br"),
        _vm.registration.firstregistrationDay
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.registration.firstregistrationDay) +
                  "." +
                  _vm._s(_vm.registration.firstregistrationMonth) +
                  "." +
                  _vm._s(_vm.registration.firstregistrationYear) +
                  " "
              )
            ])
          : _vm._e(),
        _c("br"),
        _vm.displayHuDate
          ? _c("span", [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.partner.PartnerRegistrationCard.hu")
                  )
                )
              ]),
              _c("br"),
              _vm.registration.firstregistrationDay
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.registration.huMonth) +
                        "." +
                        _vm._s(_vm.registration.huYear)
                    )
                  ])
                : _vm._e(),
              _c("br")
            ])
          : _vm._e(),
        _vm.displayDriveTyp
          ? _c("span", [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerRegistrationCard.driveTyp"
                    )
                  ) + " "
                )
              ]),
              _c("br"),
              _vm.registration.driveTyp
                ? _c("span", [_vm._v(" " + _vm._s(_vm.registration.driveTyp))])
                : _vm._e(),
              _c("br")
            ])
          : _vm._e(),
        _vm.displayVehicleClass
          ? _c("span", [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerRegistrationCard.vehicleClass"
                    )
                  )
                )
              ]),
              _c("br"),
              _vm.registration.vehicleClass
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.registration.vehicleClass) + " ")
                  ])
                : _vm._e(),
              _c("br")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }