var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "timeline-card",
    {
      attrs: {
        title: _vm.$t("timeLine.PartnerRegistrationTimeLineElement.title")
      }
    },
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.UPDATE,
            a: _vm.ResourceEnum.REPORT,
            field: _vm.report.partnerId
          }
        },
        [
          _c(
            "template",
            {
              slot: "menu"
            },
            [
              _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.CREATE,
                    a: _vm.ResourceEnum.AI,
                    field: _vm.report.partnerId
                  }
                },
                [
                  _vm.canReadRegistrationImage
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            outlined: "",
                            "x-small": "",
                            dense: "",
                            color: "info",
                            loading: _vm.loadRegistrationResult
                          },
                          on: {
                            click: _vm.readRegistrationImage
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "timeLine.PartnerRegistrationTimeLineElement.readRegistration"
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                "x-small": "",
                                dense: ""
                              }
                            },
                            [_vm._v("mdi-creation")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "confirm-action-dialog",
                    {
                      attrs: {
                        title: _vm.$t(
                          "timeLine.PartnerRegistrationTimeLineElement.readingRegistration"
                        ),
                        hideRight: true,
                        hideLeft: true,
                        hideClose: true,
                        persistent: true,
                        isDialogActive: _vm.loadRegistrationResult
                      },
                      on: {
                        "update:isDialogActive": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.loadRegistrationResult = $event
                        },
                        "update:is-dialog-active": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.loadRegistrationResult = $event
                        }
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "fill-height ma-0 pt-2",
                          attrs: {
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              color: "primary"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("registration-update-dialog", {
                ref: "dialog",
                attrs: {
                  icon: false,
                  loading: _vm.loading,
                  registration: _vm.registration,
                  registrationImageLink: _vm.registrationImageLink
                },
                on: {
                  update: _vm.update
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          !_vm.loading
            ? _c("v-data-table", {
                attrs: {
                  dense: "",
                  "item-key": "key",
                  headers: _vm.headers,
                  items: _vm.notEmptyData,
                  "hide-default-header": "",
                  "hide-default-footer": "",
                  "disable-pagination": ""
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }