var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-4  mt-4",
      attrs: {
        flat: "",
        outlined: ""
      }
    },
    [
      _c("v-card-title", [
        _vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.expired")))
      ]),
      _c("v-card-text", [
        _vm._v(
          " " + _vm._s(_vm.$t("views.PartnerSharedReport.expiredDetail")) + " "
        )
      ]),
      _c("v-card-text", [
        _c("b", [_vm._v(_vm._s(_vm.partner.companyName))]),
        _c("br"),
        _vm.partner.address
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.partner.address.street) +
                  "," +
                  _vm._s(_vm.partner.address.zip) +
                  " " +
                  _vm._s(_vm.partner.address.city)
              ),
              _c("br")
            ])
          : _vm._e(),
        _vm.partner.settings
          ? _c(
              "span",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      small: ""
                    }
                  },
                  [_vm._v("mdi-email-outline")]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "mailto:".concat(_vm.partner.settings.contact.email)
                    }
                  },
                  [_vm._v(_vm._s(_vm.partner.settings.contact.email))]
                ),
                _c("br"),
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      small: ""
                    }
                  },
                  [_vm._v("mdi-phone-outline")]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "tel:".concat(_vm.partner.settings.contact.phone)
                    }
                  },
                  [_vm._v(_vm._s(_vm.partner.settings.contact.phone))]
                ),
                _c("br")
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }