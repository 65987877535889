var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.isValid,
        callback: function callback($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c(
        "draggable",
        {
          attrs: {
            list: _vm.customFieldList,
            handle: ".handle"
          }
        },
        _vm._l(_vm.customFieldList, function(customField, index) {
          return _c(
            "v-list-item",
            {
              key: "customFieldSelection" + customField.customField.id
            },
            [
              !_vm.disabled
                ? _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "handle"
                        },
                        [_vm._v(" mdi-drag-vertical ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "ml-2 mb-n2 pt-1"
                    },
                    [
                      _c("custom-field-detail-icon", {
                        attrs: {
                          type: customField.customField.type
                        }
                      }),
                      _vm._v(" " + _vm._s(customField.customField.name) + " ")
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass: "mb-n5 mt-n3"
                    },
                    [
                      _c("v-checkbox", {
                        staticClass: "ml-2",
                        attrs: {
                          disabled: _vm.disabled,
                          label: _vm.$t("views.CustomFieldList.required")
                        },
                        model: {
                          value: customField.configuration.isRequired,
                          callback: function callback($$v) {
                            _vm.$set(
                              customField.configuration,
                              "isRequired",
                              $$v
                            )
                          },
                          expression: "customField.configuration.isRequired"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-list-item-action", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      direction: "row"
                    }
                  },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          top: "",
                          left: "",
                          "close-on-content-click": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function fn(_ref) {
                                var on = _ref.on,
                                  attrs = _ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            icon: ""
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(" mdi-eye-outline ")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-2"
                          },
                          [
                            _c("custom-field", {
                              attrs: {
                                customField: customField.customField,
                                required: customField.configuration.isRequired
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.disabled,
                          icon: ""
                        },
                        on: {
                          click: function click($event) {
                            return _vm.remove(index)
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v(" mdi-minus ")])],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        }),
        1
      ),
      !_vm.disabled
        ? _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                "nudge-bottom": "-30"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref2) {
                      var on = _ref2.on,
                        attrs = _ref2.attrs
                      return [
                        _c(
                          "div",
                          _vm._g(_vm._b({}, "div", attrs, false), on),
                          [
                            _c("v-text-field", {
                              attrs: {
                                "append-icon": "mdi-plus",
                                label: _vm.$t(
                                  "views.CustomFieldList.selectCustomField"
                                ),
                                hint: _vm.$t(
                                  "views.CustomFieldList.selectCustomFieldHint"
                                ),
                                "persistent-hint": "",
                                outlined: "",
                                clearable: ""
                              },
                              model: {
                                value: _vm.search,
                                callback: function callback($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1329532731
              )
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    outlined: "",
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticStyle: {
                        "max-height": "500px",
                        "overflow-y": "auto"
                      }
                    },
                    [
                      _vm._l(_vm.filteredCustomFields, function(item) {
                        return _c(
                          "div",
                          {
                            key: "customFieldSelection" + item.id
                          },
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function click($event) {
                                    return _vm.addNewCustomField(item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("custom-field-detail-icon", {
                                      attrs: {
                                        type: item.type
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ]),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(" " + _vm._s(item.hint) + " ")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-divider")
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: _vm.initCreation
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                          _c("v-list-item-content", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("views.CustomFieldList.newCustomField")
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isCreateDialogActive,
            title: _vm.$t("create"),
            rightDisabled: !_vm.isCreateValid,
            rightText: _vm.$t("create"),
            width: "800px"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isCreateDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isCreateDialogActive = $event
            },
            close: function close($event) {
              _vm.isCreateDialogActive = false
            },
            leftClick: function leftClick($event) {
              _vm.isCreateDialogActive = false
            },
            rightClick: _vm.onCreate
          }
        },
        [
          _c("custom-field-form", {
            attrs: {
              customField: _vm.createCustomFieldDto
            },
            model: {
              value: _vm.isCreateValid,
              callback: function callback($$v) {
                _vm.isCreateValid = $$v
              },
              expression: "isCreateValid"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }