var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "pt-4 pr-4 pl-4",
      attrs: {
        flat: "",
        "max-width": "500"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _vm._l(_vm.witnessList, function(item, index) {
            return _c(
              "div",
              {
                key: index
              },
              [
                _c(
                  "v-card-text",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("report.witness.itemTitle", {
                            number: index + 1
                          })
                        ) +
                        " "
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-2",
                        attrs: {
                          elevation: "0",
                          small: "",
                          color: "error",
                          "data-test-remove-witness": ""
                        },
                        on: {
                          click: function click($event) {
                            return _vm.removeWittness(index)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("report.witness.remove")) + " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    required: "",
                    rules: _vm.nameRule,
                    label: _vm.$t("report.witness.firstName") + " *",
                    "data-test-witness-first-name": ""
                  },
                  model: {
                    value: item.firstName,
                    callback: function callback($$v) {
                      _vm.$set(item, "firstName", $$v)
                    },
                    expression: "item.firstName"
                  }
                }),
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    required: "",
                    rules: _vm.nameRule,
                    label: _vm.$t("report.witness.lastName") + " *",
                    "data-test-witness-last-name": ""
                  },
                  model: {
                    value: item.lastName,
                    callback: function callback($$v) {
                      _vm.$set(item, "lastName", $$v)
                    },
                    expression: "item.lastName"
                  }
                }),
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    required: "",
                    rules: _vm.phoneNumberRules,
                    label: _vm.$t("report.witness.phone") + " *",
                    "data-test-witness-phone": ""
                  },
                  model: {
                    value: item.contact.phone,
                    callback: function callback($$v) {
                      _vm.$set(item.contact, "phone", $$v)
                    },
                    expression: "item.contact.phone"
                  }
                }),
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    required: "",
                    rules: _vm.emailRules,
                    label: _vm.$t("report.witness.email") + " *",
                    "data-test-witness-mail": ""
                  },
                  model: {
                    value: item.contact.email,
                    callback: function callback($$v) {
                      _vm.$set(item.contact, "email", $$v)
                    },
                    expression: "item.contact.email"
                  }
                }),
                _c("v-autocomplete", {
                  attrs: {
                    rules: _vm.roleRules,
                    outlined: "",
                    label: _vm.$t("report.witness.role") + " *",
                    items: _vm.witnessRolesItems,
                    "data-test-witness-role": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function fn(_ref) {
                          var item = _ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("report.witness.roles.".concat(item))
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "selection",
                        fn: function fn(_ref2) {
                          var item = _ref2.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("report.witness.roles.".concat(item))
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item.role,
                    callback: function callback($$v) {
                      _vm.$set(item, "role", $$v)
                    },
                    expression: "item.role"
                  }
                }),
                _vm.isInsuranceDisplayed
                  ? _c("v-checkbox", {
                      attrs: {
                        label: _vm.$t("report.witness.insurance")
                      },
                      model: {
                        value: _vm.hasInsurance,
                        callback: function callback($$v) {
                          _vm.hasInsurance = $$v
                        },
                        expression: "hasInsurance"
                      }
                    })
                  : _vm._e(),
                _vm.hasInsurance
                  ? _c("insurance-form", {
                      model: {
                        value: item.insurances[0],
                        callback: function callback($$v) {
                          _vm.$set(item.insurances, 0, $$v)
                        },
                        expression: "item.insurances[0]"
                      }
                    })
                  : _vm._e(),
                _vm.numberplate
                  ? _c("v-text-field", {
                      attrs: {
                        outlined: "",
                        label: _vm.$t("report.witness.numberplate"),
                        rules: _vm.numberplateRules,
                        "data-test-witness-numberplate": ""
                      },
                      model: {
                        value: item.numberplate,
                        callback: function callback($$v) {
                          _vm.$set(item, "numberplate", $$v)
                        },
                        expression: "item.numberplate"
                      }
                    })
                  : _vm._e(),
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t("report.witness.isInjured")
                  },
                  model: {
                    value: item.isInjured,
                    callback: function callback($$v) {
                      _vm.$set(item, "isInjured", $$v)
                    },
                    expression: "item.isInjured"
                  }
                }),
                item.isInjured
                  ? _c("v-textarea", {
                      attrs: {
                        outlined: "",
                        required: "",
                        label: _vm.$t("report.witness.isInjuredDetail") + " *",
                        rules: _vm.noteRule
                      },
                      model: {
                        value: item.note,
                        callback: function callback($$v) {
                          _vm.$set(item, "note", $$v)
                        },
                        expression: "item.note"
                      }
                    })
                  : _vm._e(),
                _c("v-card-text", [
                  _vm._v(" " + _vm._s(_vm.$t("report.witness.appendix")) + " ")
                ]),
                _c("report-image-upload", {
                  attrs: {
                    asset: _vm.assetFront,
                    example: _vm.exampleFront,
                    instruction: _vm.instruction,
                    type: _vm.typeFront,
                    imageIds: item.imageIds,
                    "data-test-image": ""
                  },
                  on: {
                    onImageUpload: function onImageUpload(image) {
                      return _vm.onImageUpload(image, index)
                    },
                    onImageDeleted: function onImageDeleted(image) {
                      return _vm.onImageDeleted(image, index)
                    }
                  }
                }),
                _c("v-divider", {
                  staticClass: "mt-2 mb-4"
                })
              ],
              1
            )
          }),
          !_vm.witnessList.length
            ? _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "0",
                    color: "success",
                    "data-test-add-witness": ""
                  },
                  on: {
                    click: _vm.addWittness
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("report.witness.add")) + " ")]
              )
            : _c(
                "span",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        elevation: "0",
                        color: "success",
                        "data-test-add-another-witness": ""
                      },
                      on: {
                        click: _vm.addWittness
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("report.witness.addFurther")))]
                  )
                ],
                1
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }