var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        isDone: _vm.isDone,
        title: _vm.header.title,
        description: _vm.header.description,
        image: _vm.header.image,
        debug: _vm.header.debug
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pt-4 pr-4 pl-4",
          attrs: {
            flat: "",
            "max-width": "500"
          }
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function callback($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-radio-group",
                {
                  model: {
                    value: _vm.policeDetails.isRecorded,
                    callback: function callback($$v) {
                      _vm.$set(_vm.policeDetails, "isRecorded", $$v)
                    },
                    expression: "policeDetails.isRecorded"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("report.policeDetails.isNotPolicy"),
                      value: false
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("report.policeDetails.isPolicy"),
                      value: true
                    }
                  })
                ],
                1
              ),
              _vm.policeDetails.isRecorded
                ? _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("report.policeDetails.department"),
                      rules: _vm.requiredRule
                    },
                    model: {
                      value: _vm.policeDetails.department,
                      callback: function callback($$v) {
                        _vm.$set(_vm.policeDetails, "department", $$v)
                      },
                      expression: "policeDetails.department"
                    }
                  })
                : _vm._e(),
              _vm.policeDetails.isRecorded
                ? _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("report.policeDetails.fileReference")
                    },
                    model: {
                      value: _vm.policeDetails.fileReference,
                      callback: function callback($$v) {
                        _vm.$set(_vm.policeDetails, "fileReference", $$v)
                      },
                      expression: "policeDetails.fileReference"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }