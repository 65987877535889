var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      key: "preference" + _vm.key,
      attrs: {
        margin: 0,
        flat: "",
        outlined: "",
        subtitle: _vm.$t(
          "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.leasingDetails.title.leasing"
        ),
        hideTitle: true
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.showRemove
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        "data-test-leasing-add-btn": ""
                      },
                      on: {
                        click: _vm.add
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                  )
                : _vm._e(),
              _vm.showRemove
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        "data-test-leasing-add-btn": ""
                      },
                      on: {
                        click: _vm.remove
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.showRemove
        ? _c(
            "div",
            {
              staticClass: "pt-2 pr-2 pl-2"
            },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("v-select", {
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.leasingDetails.isLeasing"
                      ),
                      flat: "",
                      outlined: "",
                      items: [true, false]
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function fn(_ref) {
                            var item = _ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("".concat(item ? "yes" : "no"))
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "selection",
                          fn: function fn(_ref2) {
                            var item = _ref2.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("".concat(item ? "yes" : "no"))
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2122160316
                    ),
                    model: {
                      value: _vm.reportDto.leasing.isLeasing,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto.leasing, "isLeasing", $$v)
                      },
                      expression: "reportDto.leasing.isLeasing"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.leasingDetails.company"
                      ),
                      flat: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.reportDto.leasing.company,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto.leasing, "company", $$v)
                      },
                      expression: "reportDto.leasing.company"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.leasingDetails.claimNumber"
                      ),
                      flat: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.reportDto.leasing.claimNumber,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto.leasing, "claimNumber", $$v)
                      },
                      expression: "reportDto.leasing.claimNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }