var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        hasNext: "false",
        title: _vm.header.title,
        description: _vm.header.description,
        debug: _vm.report
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pl-3 pr-3",
          attrs: {
            flat: "",
            "max-width": "500"
          }
        },
        [
          _vm.email
            ? _c("div", {
                staticClass: "pl-1 text"
              })
            : _vm._e(),
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function callback($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("v-checkbox", {
                ref: "reviewed",
                staticClass: "padded",
                attrs: {
                  rules: _vm.reportReviewedRules,
                  label: _vm.$t("report.summary.inputIsValid"),
                  "data-test-review-checkbox": ""
                },
                model: {
                  value: _vm.isReviewed,
                  callback: function callback($$v) {
                    _vm.isReviewed = $$v
                  },
                  expression: "isReviewed"
                }
              }),
              _c("v-checkbox", {
                ref: "privacy",
                staticClass: "padded",
                attrs: {
                  rules: [
                    function(v) {
                      return !!v || _vm.$t("report.summary.readPrivacy")
                    }
                  ],
                  label: _vm.$t("report.summary.privacyPolicyText"),
                  "data-test-privacy-checkbox": ""
                },
                model: {
                  value: _vm.isPrivacyChecked,
                  callback: function callback($$v) {
                    _vm.isPrivacyChecked = $$v
                  },
                  expression: "isPrivacyChecked"
                }
              }),
              _c(
                "v-row",
                {
                  staticClass: "justify-center align-center pt-8"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "submitButton",
                      attrs: {
                        color: "success",
                        rounded: "",
                        "x-large": "",
                        disabled: !_vm.isDone,
                        loading: _vm.isLoading,
                        "data-test-submit-button": ""
                      },
                      on: {
                        click: _vm.submit
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            left: ""
                          }
                        },
                        [_vm._v(" mdi-send ")]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("report.summary.buttonTitle")) + " "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }