var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true
      },
      on: {
        logoClick: _vm.goHome
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "8"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: "",
                        "max-width": "500"
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "p",
                            {
                              staticClass: "display-1 text--primary"
                            },
                            [_vm._v(_vm._s(_vm.$t(_vm.title)))]
                          ),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(_vm.description)
                            }
                          }),
                          _c("login-register-button", {
                            attrs: {
                              large: !_vm.isMobile,
                              small: _vm.isMobile
                            }
                          }),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-4"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12"
                                  }
                                },
                                [
                                  _vm.feedbackCanBeSubmitted &&
                                  _vm.isFeedbackEnabled
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: _vm.isMobile,
                                            large: !_vm.isMobile,
                                            block: "",
                                            text: !_vm.isMobile,
                                            outlined: _vm.isMobile,
                                            secondary: "",
                                            "data-test-open-feedback-dialog": ""
                                          },
                                          on: {
                                            click: function click($event) {
                                              _vm.isFeedbackDialogActive = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "report.success.giveFeedback"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "confirm-action-dialog",
                    {
                      attrs: {
                        isDialogActive: _vm.isFeedbackDialogActive,
                        title: _vm.$t("report.success.giveFeedbackTitle"),
                        hideLeft: true,
                        hideRight: true
                      },
                      on: {
                        "update:isDialogActive": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.isFeedbackDialogActive = $event
                        },
                        "update:is-dialog-active": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.isFeedbackDialogActive = $event
                        }
                      }
                    },
                    [
                      _c("feedback-container", {
                        attrs: {
                          keyForEndpoint: _vm.key
                        },
                        on: {
                          sent: _vm.onFeedbackSent
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "4"
                  }
                },
                [
                  _vm.isAdvertisementEnabled
                    ? _c("advertisement-card", {
                        attrs: {
                          title: _vm.$t(_vm.adTitle),
                          subtitle: _vm.$t(_vm.adSubtitle),
                          content: _vm.$t(_vm.adContent),
                          btnText: _vm.$t(_vm.adBtnText),
                          imgSrc:
                            "https://static.mmmint.ai/wirkaufendeinethg/forest-car-street.jpg",
                          logoSrc:
                            "https://static.mmmint.ai/wirkaufendeinethg/wirkaufendeinethg_logo.png",
                          link: "https://www.wirkaufendeinethg.de",
                          backgroundColor: "#e4f2ee",
                          buttonColor: "#86c900"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }