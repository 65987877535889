var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "timeline-card",
    {
      attrs: {
        title: _vm.$t("timeLine.ActivityTimeLineItemTicket.title", {
          name: _vm.name,
          date: _vm.date
        })
      },
      scopedSlots: _vm._u([
        {
          key: "menu",
          fn: function fn() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "mt-n1",
                  attrs: {
                    "x-small": "",
                    outlined: "",
                    color: "info",
                    disabled: !_vm.ticket
                  },
                  on: {
                    click: _vm.goToDetail
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("timeLine.ActivityTimeLineItemTicket.detail")
                      ) +
                      " "
                  )
                ]
              ),
              _c("activity-time-line-item-delete", {
                attrs: {
                  partnerId: _vm.activityLogEntry.partnerId,
                  activityId: _vm.activityLogEntry.id
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isLoading
        ? _c("v-skeleton-loader", {
            attrs: {
              type: "article"
            }
          })
        : _vm.ticket
        ? _c(
            "div",
            [
              _c("refs-ticket", {
                ref: "refCard",
                attrs: {
                  item: _vm.ticket
                },
                on: {
                  openDetail: _vm.goToDetail
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }