var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        isDone: _vm.isDone,
        title: _vm.header.title,
        description: _vm.header.description,
        image: _vm.header.image,
        debug: _vm.header.debug
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pt-4 pr-4 pl-4",
          attrs: {
            flat: "",
            "max-width": "800"
          }
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.valid,
                callback: function callback($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _vm.isNumberplate
                ? _c("numberplate-form", {
                    staticClass: "mb-4",
                    attrs: {
                      valid: _vm.validNumberplate
                    },
                    on: {
                      valid: _vm.setValidNumberplate
                    },
                    model: {
                      value: _vm.numberplate,
                      callback: function callback($$v) {
                        _vm.numberplate = $$v
                      },
                      expression: "numberplate"
                    }
                  })
                : _vm._e(),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: _vm.$t("report.externalidhueffermann.externalId")
                },
                model: {
                  value: _vm.externalId,
                  callback: function callback($$v) {
                    _vm.externalId = $$v
                  },
                  expression: "externalId"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }