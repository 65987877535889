var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-text-field", {
    attrs: {
      outlined: "",
      label: _vm.$t(_vm.label)
    },
    scopedSlots: _vm._u([
      {
        key: "prepend-inner",
        fn: function fn() {
          return [
            _c("v-icon", [_vm._v(" " + _vm._s(_vm.options.prefix) + " ")])
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.valueLocal,
      callback: function callback($$v) {
        _vm.valueLocal = $$v
      },
      expression: "valueLocal"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }