var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(_vm.damageLocatorComponentName, {
    tag: "component",
    attrs: {
      "damage-locations": _vm.damageLocations,
      clickedColor: _vm.clickedColor,
      clickable: _vm.clickable,
      readOnly: _vm.readOnly
    },
    on: {
      svgClicked: function svgClicked($event) {
        return _vm.$emit("svgClicked", $event)
      },
      damageLocation: function damageLocation($event) {
        return _vm.$emit("damageLocation", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }