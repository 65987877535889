var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      key: "preference" + _vm.key,
      attrs: {
        margin: 0,
        flat: "",
        outlined: "",
        subtitle: _vm.$t(
          "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationDate.title"
        ),
        hideTitle: true
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.isRegistrationDate
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: ""
                      },
                      on: {
                        click: _vm.add
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                  )
                : _vm._e(),
              _vm.showRemove
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: ""
                      },
                      on: {
                        click: _vm.remove
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isRegistrationDate
        ? _c(
            "div",
            {
              staticClass: "pt-2 pr-2 pl-2"
            },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("date-time-picker", {
                    attrs: {
                      date: _vm.registrationDate,
                      hideTime: true
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }