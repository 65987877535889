var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.isValid,
        callback: function callback($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: _vm.showConfiguration ? "6" : "12"
              }
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    "no-gutters": ""
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "card",
                        {
                          staticClass: "pr-2 pt-4 pl-2",
                          attrs: {
                            hideTitle: true,
                            margin: 0
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label:
                                _vm.$t("views.CustomFieldList.name") + " *",
                              hint: _vm.$t("views.CustomFieldList.nameHint"),
                              outlined: "",
                              "persistent-hint": "",
                              loading: _vm.loading,
                              disabled: _vm.isDisabled,
                              rules: _vm.requiredRule
                            },
                            on: {
                              input: _vm.deriveKeyFromName
                            },
                            model: {
                              value: _vm.customField.name,
                              callback: function callback($$v) {
                                _vm.$set(_vm.customField, "name", $$v)
                              },
                              expression: "customField.name"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("views.CustomFieldList.key") + " *",
                              hint: _vm.$t("views.CustomFieldList.keyHint"),
                              outlined: "",
                              "persistent-hint": "",
                              loading: _vm.loading,
                              disabled: _vm.isDisabled,
                              rules: _vm.requiredRule
                            },
                            on: {
                              keyup: _vm.makeKeyKeylike
                            },
                            model: {
                              value: _vm.customField.key,
                              callback: function callback($$v) {
                                _vm.$set(_vm.customField, "key", $$v)
                              },
                              expression: "customField.key"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "card",
                        {
                          staticClass: "mt-6 pr-2 pt-4 pl-2",
                          attrs: {
                            hideTitle: true,
                            margin: 0
                          }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label:
                                _vm.$t("views.CustomFieldList.type") + " *",
                              hint: _vm.$t("views.CustomFieldList.typeHint"),
                              outlined: "",
                              "persistent-hint": "",
                              items: _vm.customFieldValues,
                              loading: _vm.loading,
                              disabled: !!_vm.customField.id,
                              rules: _vm.requiredRule,
                              appendIcon: _vm.getIconForField(
                                _vm.customField.type
                              )
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function fn(_ref) {
                                  var item = _ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.CustomFieldList.types.".concat(
                                              item
                                            )
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("v-spacer"),
                                    _c("custom-field-detail-icon", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        type: item
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "selection",
                                fn: function fn(_ref2) {
                                  var item = _ref2.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.CustomFieldList.types.".concat(
                                              item
                                            )
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.customField.type,
                              callback: function callback($$v) {
                                _vm.$set(_vm.customField, "type", $$v)
                              },
                              expression: "customField.type"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label:
                                _vm.$t("views.CustomFieldList.label") + " *",
                              hint: _vm.$t("views.CustomFieldList.labelHint"),
                              outlined: "",
                              "persistent-hint": "",
                              loading: _vm.loading,
                              disabled: _vm.isDisabled,
                              rules: _vm.requiredRule
                            },
                            model: {
                              value: _vm.customField.label,
                              callback: function callback($$v) {
                                _vm.$set(_vm.customField, "label", $$v)
                              },
                              expression: "customField.label"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("views.CustomFieldList.hint"),
                              hint: _vm.$t("views.CustomFieldList.hintHint"),
                              outlined: "",
                              "persistent-hint": "",
                              loading: _vm.loading,
                              disabled: _vm.isDisabled
                            },
                            model: {
                              value: _vm.customField.hint,
                              callback: function callback($$v) {
                                _vm.$set(_vm.customField, "hint", $$v)
                              },
                              expression: "customField.hint"
                            }
                          }),
                          _vm.hasPlaceholder
                            ? _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "views.CustomFieldList.placeholder"
                                  ),
                                  hint: _vm.$t(
                                    "views.CustomFieldList.placeholderHint"
                                  ),
                                  outlined: "",
                                  "persistent-hint": "",
                                  loading: _vm.loading,
                                  disabled: _vm.isDisabled
                                },
                                model: {
                                  value: _vm.customField.placeholder,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.customField,
                                      "placeholder",
                                      $$v
                                    )
                                  },
                                  expression: "customField.placeholder"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.$vuetify.breakpoint.smAndDown
                    ? _c(
                        "v-col",
                        {
                          staticClass: "mt-6",
                          attrs: {
                            cols: "12"
                          }
                        },
                        [
                          _c(
                            "card",
                            {
                              attrs: {
                                subtitle: _vm.$t(
                                  "views.CustomFieldList.preview"
                                ),
                                hideTitle: true,
                                margin: 0
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function fn() {
                                      return [
                                        _c("v-switch", {
                                          staticClass: "mr-1 ml-n1",
                                          attrs: {
                                            label: _vm.$t(
                                              "views.CustomFieldList.required"
                                            )
                                          },
                                          model: {
                                            value: _vm.isPreviewRequired,
                                            callback: function callback($$v) {
                                              _vm.isPreviewRequired = $$v
                                            },
                                            expression: "isPreviewRequired"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2206380630
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pr-2 pt-4 pl-2"
                                },
                                [
                                  _c("custom-field", {
                                    staticClass: "mr-2 ml-2 mt-2",
                                    attrs: {
                                      customField: _vm.customField,
                                      required: _vm.isPreviewRequired
                                    },
                                    model: {
                                      value: _vm.previewValue,
                                      callback: function callback($$v) {
                                        _vm.previewValue = $$v
                                      },
                                      expression: "previewValue"
                                    }
                                  }),
                                  _vm.previewValue
                                    ? _c("v-card-subtitle", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "views.CustomFieldList.value"
                                              )
                                            ) +
                                            ": " +
                                            _vm._s(_vm.previewValue) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.showConfiguration
            ? _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pt-3",
                      attrs: {
                        outlined: ""
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "draggable",
                            {
                              attrs: {
                                list: _vm.values,
                                handle: ".handle",
                                disabled: _vm.isDisabled
                              }
                            },
                            _vm._l(_vm.values, function(value, index) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "customField.configuration.values" +
                                    JSON.stringify(value) +
                                    index +
                                    _vm.removeUpdateKey
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-action",
                                        {
                                          staticClass: "pa-0 ma-0 ml-n2 mr-1"
                                        },
                                        [
                                          !_vm.isDisabled
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "handle"
                                                },
                                                [_vm._v(" mdi-drag-vertical ")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c("custom-field-value-chip", {
                                                attrs: {
                                                  value: value
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-action", [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "row"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.loading ||
                                                    _vm.isDisabled,
                                                  rules: _vm.requiredRule,
                                                  icon: ""
                                                },
                                                on: {
                                                  click: function click(
                                                    $event
                                                  ) {
                                                    return _vm.startEditConfigurationValue(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(" mdi-pencil ")
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.loading ||
                                                    _vm.isDisabled,
                                                  icon: ""
                                                },
                                                on: {
                                                  click: function click(
                                                    $event
                                                  ) {
                                                    return _vm.removeConfiguration(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(" mdi-close ")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticStyle: {
                                display: "flex",
                                align: "top"
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      loading: _vm.loading,
                                      disabled: _vm.isDisabled,
                                      label: _vm.$t(
                                        "views.CustomFieldList.values"
                                      ),
                                      hint: _vm.$t(
                                        "views.CustomFieldList.valuesHint"
                                      ),
                                      rules: _vm.values.length
                                        ? []
                                        : _vm.requiredCustomFieldRule,
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.newConfigurationValue,
                                      callback: function callback($$v) {
                                        _vm.newConfigurationValue = $$v
                                      },
                                      expression: "newConfigurationValue"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-n7",
                                      attrs: {
                                        disabled:
                                          _vm.loading ||
                                          _vm.isDisabled ||
                                          !_vm.newConfigurationValue,
                                        height: 57,
                                        text: "",
                                        outlined: ""
                                      },
                                      on: {
                                        click: _vm.addNewConfiguration
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("views.CustomFieldList.add")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        outlined: ""
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mr-1 ml-1",
                          attrs: {
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("views.CustomFieldList.preview")) +
                              " "
                          ),
                          _c("v-spacer"),
                          _c("v-switch", {
                            staticClass: "mr-1 ml-n1",
                            attrs: {
                              label: _vm.$t("views.CustomFieldList.required")
                            },
                            model: {
                              value: _vm.isPreviewRequired,
                              callback: function callback($$v) {
                                _vm.isPreviewRequired = $$v
                              },
                              expression: "isPreviewRequired"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c("custom-field", {
                        staticClass: "mr-2 ml-2 mt-2",
                        attrs: {
                          customField: _vm.customField,
                          required: _vm.isPreviewRequired
                        },
                        model: {
                          value: _vm.previewValue,
                          callback: function callback($$v) {
                            _vm.previewValue = $$v
                          },
                          expression: "previewValue"
                        }
                      }),
                      _vm.previewValue
                        ? _c("v-card-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("views.CustomFieldList.value")) +
                                ": " +
                                _vm._s(_vm.previewValue) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.editConfigurationValue
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isEditConfigurationDialog
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isEditConfigurationDialog = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isEditConfigurationDialog = $event
                },
                leftClick: function leftClick($event) {
                  _vm.isEditConfigurationDialog = false
                },
                rightClick: function rightClick($event) {
                  _vm.isEditConfigurationDialog = false
                }
              }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "mt-3 mb-7"
                },
                [
                  _c("v-spacer"),
                  _c("custom-field-value-chip", {
                    attrs: {
                      value: _vm.editConfigurationValue,
                      chipStyle: "transform: scale(1.75)"
                    }
                  }),
                  _c("v-spacer")
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                {
                  staticClass: "mt-1 mb-2"
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        row: ""
                      },
                      model: {
                        value: _vm.editConfigurationValue.color,
                        callback: function callback($$v) {
                          _vm.$set(_vm.editConfigurationValue, "color", $$v)
                        },
                        expression: "editConfigurationValue.color"
                      }
                    },
                    _vm._l(_vm.colors, function(color) {
                      return _c(
                        "v-sheet",
                        {
                          key: color,
                          staticClass: "mr-1 ml-1",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          },
                          attrs: {
                            width: "45px",
                            height: "45px",
                            rounded: "",
                            color: "".concat(color, " lighten-3")
                          }
                        },
                        [
                          _c("v-radio", {
                            staticClass: "mr-n2",
                            attrs: {
                              color: "white",
                              value: color
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c("v-spacer")
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "ma-1"
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: "value"
                    },
                    model: {
                      value: _vm.editConfigurationValue.value,
                      callback: function callback($$v) {
                        _vm.$set(_vm.editConfigurationValue, "value", $$v)
                      },
                      expression: "editConfigurationValue.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "ma-1"
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: "description"
                    },
                    model: {
                      value: _vm.editConfigurationValue.description,
                      callback: function callback($$v) {
                        _vm.$set(_vm.editConfigurationValue, "description", $$v)
                      },
                      expression: "editConfigurationValue.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }