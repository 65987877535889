var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "ma-2",
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: ""
      }
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "title pt-4",
          class: _vm.color
        },
        [
          _c(
            "h4",
            {
              staticClass: "text-truncate"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "components.partner.PartnerReportDetailInfoMessageCard.title"
                  )
                )
              )
            ]
          )
        ]
      ),
      _c("v-divider"),
      _c("v-card-text", [_vm._v(" " + _vm._s(_vm.message) + " ")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }