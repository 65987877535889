var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "v-btn",
        {
          attrs: {
            icon: ""
          },
          on: {
            click: function click($event) {
              _vm.isDialogActive = !_vm.isDialogActive
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-image-album")])],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDialogActive,
            hideLeft: true,
            title: " ",
            rightText: "Download"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            rightClick: _vm.download
          }
        },
        [
          _c(
            "v-card-title",
            [
              _c("v-spacer"),
              _c("file-download", {
                ref: "download",
                staticStyle: {
                  display: "none"
                },
                attrs: {
                  hidden: "",
                  icon: false,
                  downloadFiles: [
                    {
                      url: _vm.image.url,
                      filename: _vm.image.name
                    }
                  ]
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-img", {
                attrs: {
                  src: _vm.image.url,
                  "max-width": 550
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }