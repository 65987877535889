var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.isBody
        ? _c(
            "timeline-card",
            {
              attrs: {
                title: _vm.$t(
                  "components.partner.PartnerReportDetailInfoBody.title"
                )
              },
              scopedSlots: _vm._u(
                [
                  _vm.canUpdateReport
                    ? {
                        key: "menu",
                        fn: function fn() {
                          return [
                            _c("partner-report-update-report-dialog", {
                              attrs: {
                                config: [_vm.ReportUpdateTabEnum.body],
                                activityTarget: "body"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-simple-table",
                    {
                      attrs: {
                        dense: ""
                      }
                    },
                    [
                      _c(
                        "tbody",
                        _vm._l(_vm.body || [], function(item, i) {
                          return _c(
                            "tr",
                            {
                              key: i
                            },
                            [
                              _c("td", [_vm._v(_vm._s(Object.keys(item)[0]))]),
                              _c("td", [_vm._v(_vm._s(Object.values(item)[0]))])
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasLiabilityDamage
        ? _c(
            "timeline-card",
            {
              attrs: {
                title: _vm.$t("report.liabilityDamage.title")
              }
            },
            [
              _vm.isLiabilityDamage
                ? _c("v-card-text", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("report.liabilityDamage.cards.true.description")
                      )
                    )
                  ])
                : _c("v-card-text", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("report.liabilityDamage.cards.false.description")
                      )
                    )
                  ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }