var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        isDone: _vm.isDone,
        title: _vm.configuration.header.title,
        description: _vm.configuration.header.description,
        image: _vm.configuration.header.image,
        debug: _vm.configuration.header.debug,
        hasInstructions: _vm.configuration.instruction ? true : false,
        instructionPictures: _vm.configuration.instruction.pictures,
        instructionText: _vm.configuration.instruction.text,
        instructionTitle: _vm.configuration.instruction.title
      }
    },
    [
      _c("FileUploadMulti", {
        attrs: {
          instruction: _vm.configuration.instruction,
          snackbarTitle: _vm.multiSteps.frontLeft.text,
          example: _vm.multiSteps.frontLeft.example,
          overlay: _vm.multiSteps.frontLeft.overlay,
          text: _vm.multiSteps.frontLeft.text,
          textAlt: _vm.multiSteps.frontLeft.textAlt,
          filesCb: _vm.multiSteps.frontLeft.filesCb,
          displayImages: _vm.getOverviewFrontLeft,
          handleDeleteCb: _vm.multiSteps.frontLeft.handleDelete,
          handleUploadCb: _vm.multiSteps.frontLeft.handleUpload,
          "data-test-front-left-image-upload": ""
        }
      }),
      _c("FileUploadMulti", {
        attrs: {
          instruction: _vm.configuration.instruction,
          snackbarTitle: _vm.multiSteps.rearLeft.text,
          example: _vm.multiSteps.rearLeft.example,
          overlay: _vm.multiSteps.rearLeft.overlay,
          text: _vm.multiSteps.rearLeft.text,
          textAlt: _vm.multiSteps.rearLeft.textAlt,
          filesCb: _vm.multiSteps.rearLeft.filesCb,
          displayImages: _vm.getOverviewRearLeft,
          handleDeleteCb: _vm.multiSteps.rearLeft.handleDelete,
          handleUploadCb: _vm.multiSteps.rearLeft.handleUpload,
          "data-test-rear-left-image-upload": ""
        }
      }),
      _c("FileUploadMulti", {
        attrs: {
          instruction: _vm.configuration.instruction,
          snackbarTitle: _vm.multiSteps.rearRight.text,
          example: _vm.multiSteps.rearRight.example,
          overlay: _vm.multiSteps.rearRight.overlay,
          text: _vm.multiSteps.rearRight.text,
          textAlt: _vm.multiSteps.rearRight.textAlt,
          filesCb: _vm.multiSteps.rearRight.filesCb,
          displayImages: _vm.getOverviewRearRight,
          handleDeleteCb: _vm.multiSteps.rearRight.handleDelete,
          handleUploadCb: _vm.multiSteps.rearRight.handleUpload,
          "data-test-rear-right-image-upload": ""
        }
      }),
      _c("FileUploadMulti", {
        attrs: {
          example: _vm.multiSteps.frontRight.example,
          instruction: _vm.configuration.instruction,
          snackbarTitle: _vm.multiSteps.frontRight.text,
          overlay: _vm.multiSteps.frontRight.overlay,
          text: _vm.multiSteps.frontRight.text,
          textAlt: _vm.multiSteps.frontRight.textAlt,
          filesCb: _vm.multiSteps.frontRight.filesCb,
          displayImages: _vm.getOverviewFrontRight,
          handleDeleteCb: _vm.multiSteps.frontRight.handleDelete,
          handleUploadCb: _vm.multiSteps.frontRight.handleUpload,
          "data-test-front-right-image-upload": ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }