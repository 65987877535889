var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        isDone: _vm.isDone,
        title: _vm.header.title,
        description: _vm.header.description,
        hasInstructions: true,
        instructionPictures: _vm.instruction.pictures,
        instructionText: _vm.instruction.text,
        instructionTitle: _vm.instruction.title,
        image: _vm.header.image,
        debug: _vm.header.debug
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pt-4",
          attrs: {
            flat: "",
            "max-width": "500"
          }
        },
        [
          _c("report-image-upload", {
            attrs: {
              example: _vm.example,
              instruction: _vm.instruction,
              type: _vm.type,
              text: "report.damagenotice.textBtnAlt",
              textAlt: "report.damagenotice.textBtnAlt",
              categoryKey: _vm.damageNotice
            }
          })
        ],
        1
      ),
      _c("v-card-text", [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://static.mmmint.ai/public-other/PSA_VHV_Schadenanzeige.pdf",
              target: "_blank"
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("report.damagenotice.help")))]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }