var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("layout-report-with-banner", {
    attrs: {
      showFooter: false,
      isProgressDone: true
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function fn() {
          return [
            _c("notifications", {
              staticClass: "ma-1"
            }),
            _c("statistics-card-row", {
              attrs: {
                data: _vm.rowData,
                loading: _vm.loading
              }
            }),
            _c("debug", {
              attrs: {
                debug: _vm.items
              }
            }),
            _c("table-wrapper", {
              attrs: {
                headers: _vm.headers,
                allItems: _vm.items,
                loading: _vm.loading,
                sortBy: "timestamp.created",
                sortDesc: true
              },
              on: {
                "click:row": _vm.openSignature
              },
              scopedSlots: _vm._u([
                {
                  key: "item.timestamp.created",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("simpleDoubleDigitDate")(
                              item.timestamp.created
                            )
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.status",
                  fn: function fn(_ref2) {
                    var item = _ref2.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            small: "",
                            color: _vm.colorByStatus.get(item.status)
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "sign.DigitalSignatureRequestStatus.".concat(
                                    item.status
                                  )
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _vm.selectedId
              ? _c("my-signature-detail-side-card", {
                  attrs: {
                    signature: _vm.selectedSignature,
                    documents: _vm.selectedDocuments
                  },
                  on: {
                    close: function close($event) {
                      _vm.selectedId = null
                    },
                    reload: _vm.load
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }