var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      ref: "form",
      model: {
        value: _vm.valid,
        callback: function callback($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          outlined: "",
          label: _vm.$t("report.insurance.name") + (_vm.required ? " *" : ""),
          required: _vm.required,
          rules: _vm.requiredRule,
          "data-test-insurance-name": ""
        },
        model: {
          value: _vm.insurance.name,
          callback: function callback($$v) {
            _vm.$set(_vm.insurance, "name", $$v)
          },
          expression: "insurance.name"
        }
      }),
      _c("v-text-field", {
        attrs: {
          outlined: "",
          label: _vm.$t("report.insurance.number"),
          "data-test-insurance-number": ""
        },
        model: {
          value: _vm.insurance.number,
          callback: function callback($$v) {
            _vm.$set(_vm.insurance, "number", $$v)
          },
          expression: "insurance.number"
        }
      }),
      !_vm.type
        ? _c("v-autocomplete", {
            attrs: {
              outlined: "",
              label: _vm.$t("report.insurance.type"),
              items: _vm.types,
              "data-test-insurance-type": "",
              clearable: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("report.insurance.types.".concat(item))
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "selection",
                  fn: function fn(_ref2) {
                    var item = _ref2.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("report.insurance.types.".concat(item))
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1307057596
            ),
            model: {
              value: _vm.insurance.type,
              callback: function callback($$v) {
                _vm.$set(_vm.insurance, "type", $$v)
              },
              expression: "insurance.type"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }