var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card-actions",
        [
          !_vm.isSearchEditActive
            ? _c("div", [
                _vm.isLoading
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.userSearching",
                            {
                              search: _vm.searchText
                            }
                          )
                        )
                      }
                    })
                  : !_vm.identities.length
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.userSearchingNotFound",
                            {
                              search: _vm.searchText
                            }
                          )
                        )
                      }
                    })
                  : _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.userSearchingFound",
                            {
                              search: _vm.searchText
                            }
                          )
                        )
                      }
                    })
              ])
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                "x-small": "",
                dense: "",
                outlined: "",
                color: "info"
              },
              on: {
                click: _vm.toggleSearchEdit
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerReportDetail.SendToKsrDialog.editSearch"
                    )
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _vm.isLoading
        ? _c("v-progress-linear", {
            attrs: {
              indeterminate: ""
            }
          })
        : _vm._e(),
      _vm.isSearchEditActive
        ? _c(
            "div",
            [
              _c(
                "v-card-actions",
                {
                  staticClass: "mb-n7 mt-n6"
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        row: ""
                      },
                      model: {
                        value: _vm.contactOption,
                        callback: function callback($$v) {
                          _vm.contactOption = $$v
                        },
                        expression: "contactOption"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.mail"
                          ),
                          value: _vm.ContactOptions.MAIL
                        }
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.phone"
                          ),
                          value: _vm.ContactOptions.PHONE
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "tooltip",
                {
                  attrs: {
                    text: _vm.$t(
                      "components.partner.PartnerReportDetail.SendToKsrDialog.searchUserHint"
                    )
                  }
                },
                [
                  _c(
                    "v-form",
                    {
                      on: {
                        submit: function submit($event) {
                          $event.preventDefault()
                          return _vm.searchUser.apply(null, arguments)
                        },
                        keydown: function keydown($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.searchUser.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          loading: _vm.isLoading,
                          outlined: "",
                          label: _vm.contactOption
                            ? _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.mail"
                              )
                            : _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.phoneNumber"
                              ),
                          "append-icon": "mdi-magnify",
                          "persistent-hint": ""
                        },
                        on: {
                          "click:append": _vm.searchUser
                        },
                        model: {
                          value: _vm.search,
                          callback: function callback($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.identities, function(identity, index) {
        return _c(
          "card",
          {
            key: "" + identity.addressId + identity.contactId + index,
            staticClass: "mt-1 mb-1",
            attrs: {
              hideTitle: true,
              margin: 0
            }
          },
          [
            _c(
              "tooltip",
              {
                attrs: {
                  text: _vm.getAddressText(identity)
                }
              },
              [
                _c(
                  "v-list-item",
                  {
                    on: {
                      click: function click($event) {
                        return _vm.confirm({
                          addressId: identity.addressId,
                          contactId: identity.contactId
                        })
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      [_c("v-icon", [_vm._v(" mdi-face-man-shimmer ")])],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-subtitle", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.createForUser",
                                {
                                  search: _vm.searchText
                                }
                              )
                            )
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c("v-card-actions"),
      _c(
        "card",
        {
          staticClass: "mt-1 mb-1",
          attrs: {
            hideTitle: true,
            margin: 0,
            loading: _vm.loadXml
          }
        },
        [
          _c(
            "tooltip",
            {
              attrs: {
                text: _vm.$t(
                  "components.partner.PartnerReportDetail.SendToKsrDialog.downloadXMLTooltip"
                )
              }
            },
            [
              _c(
                "v-list-item",
                {
                  attrs: {
                    disabled: _vm.loadXml
                  },
                  on: {
                    click: _vm.downloadXml
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            loading: true
                          }
                        },
                        [_vm._v(" mdi-download ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.downloadXML"
                              )
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "card",
        {
          staticClass: "mt-1 mb-1",
          attrs: {
            hideTitle: true,
            margin: 0
          }
        },
        [
          _c(
            "tooltip",
            {
              attrs: {
                text: _vm.$t(
                  "components.partner.PartnerReportDetail.SendToKsrDialog.tooltipAPIExport"
                )
              }
            },
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function click($event) {
                      return _vm.confirm({})
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v(" mdi-new-box ")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.SendToKsrDialog.apiExport"
                              )
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }