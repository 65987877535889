var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("activity-time-line-item-document-card", {
    attrs: {
      isLoading: _vm.isLoading,
      document: _vm.document,
      name: _vm.name,
      created: _vm.date,
      activityLogEntry: _vm.activityLogEntry,
      source: _vm.source
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }