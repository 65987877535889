var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("file-upload-preview", {
    attrs: {
      maxFiles: 1,
      example: _vm.AssetEnum.image,
      filesCb: _vm.displayImages,
      displayFiles: _vm.displayImages,
      onDelete: _vm.handleDelete,
      onUpload: _vm.handleUpload,
      textAlt: "report.additionalImage.textBtnAlt"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }