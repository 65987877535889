var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      class: _vm.outlined ? "px-2" : "",
      attrs: {
        flat: "",
        outlined: _vm.outlined
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex"
          }
        },
        [
          _c(
            "v-chip-group",
            {
              model: {
                value: _vm.selectedCategory,
                callback: function callback($$v) {
                  _vm.selectedCategory = $$v
                },
                expression: "selectedCategory"
              }
            },
            [
              !_vm.hideSuggestions
                ? _c(
                    "v-chip",
                    {
                      attrs: {
                        color:
                          _vm.selectedCategory === _vm.SUGGESTION ? "info" : "",
                        value: _vm.SUGGESTION
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "mr-1",
                          staticStyle: {
                            "margin-top": "-2px"
                          }
                        },
                        [
                          _c("a-eicon", {
                            attrs: {
                              noColor: true
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("common.nouns.suggestion")) + " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.selectableCategories, function(cat) {
                return _c("v-chip", {
                  key: "refs-select-cats" + cat,
                  attrs: {
                    color: _vm.selectedCategory === cat ? "info" : "",
                    value: cat
                  },
                  domProps: {
                    textContent: _vm._s(_vm.getRefTypeName(cat))
                  }
                })
              })
            ],
            2
          )
        ],
        1
      ),
      _vm.SUGGESTION === _vm.selectedCategory && !_vm.hideSuggestions
        ? _c("refs-selection-suggestion", {
            attrs: {
              loading: _vm.loading,
              suggested: _vm.suggested,
              selected: _vm.selected,
              partnerId: _vm.partnerId
            },
            on: {
              select: _vm.addToSelected
            }
          })
        : _vm._e(),
      Object.values(_vm.BackendResourceEnum).includes(_vm.selectedCategory)
        ? _c("refs-selection-by-type", {
            key: "refs-select-by-type" + _vm.selectedCategory,
            attrs: {
              refType: _vm.selectedCategory,
              partnerId: _vm.partnerId,
              selected: _vm.selected,
              suggested: _vm.suggested
            },
            on: {
              select: function select(item) {
                return _vm.addToSelected({
                  id: item.id,
                  ref: item,
                  refType: _vm.selectedCategory
                })
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }