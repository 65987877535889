var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-timeline-item",
        {
          staticClass: "pt-6 mb-n6",
          attrs: {
            icon: "mdi-file-sign",
            "fill-dot": "",
            color: "success",
            small: ""
          }
        },
        [
          _c("b", [_vm._v(_vm._s(_vm.name) + " ")]),
          _vm._v(
            _vm._s(
              _vm.$t("timeLine.ActivityTimeLineItemSignRequest.title", {
                date: _vm.date
              })
            ) + " "
          )
        ]
      ),
      _vm.errorState
        ? _c(
            "div",
            [
              _c(
                "v-timeline-item",
                {
                  attrs: {
                    small: "",
                    icon: "mdi-close",
                    color: "error",
                    "fill-dot": ""
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center"
                          }
                        },
                        [
                          _c("v-card-text", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "timeLine.ActivityTimeLineItemSignRequest.error"
                                )
                              )
                            }
                          }),
                          _vm.activityLogEntry
                            ? _c("activity-time-line-item-delete", {
                                attrs: {
                                  partnerId: _vm.activityLogEntry.partnerId,
                                  activityId: _vm.activityLogEntry.id
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.loading
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                small: "",
                icon: "mdi-file-sign",
                "fill-dot": ""
              }
            },
            [
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: {
                  type: "paragraph"
                }
              })
            ],
            1
          )
        : _vm.signRequest
        ? _c(
            "div",
            [
              _c(
                "timeline-card",
                {
                  attrs: {
                    id: _vm.document ? _vm.document.id : "document",
                    showAvatar: false,
                    title: _vm.$t(
                      "timeLine.ActivityTimeLineItemSignRequest.subtitle",
                      {
                        email: _vm.signRequest.recipient.email
                      }
                    ),
                    showDelete: true
                  }
                },
                [
                  _c(
                    "template",
                    {
                      slot: "menu"
                    },
                    [
                      _vm.$vuetify.breakpoint.smAndDown
                        ? _c(
                            "context-menu",
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: _vm.copyLinkToClipboard
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "timeLine.ActivityTimeLineItemSignRequest.link"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function click($event) {
                                          return _vm.toDetail(
                                            _vm.signRequest.partnerId,
                                            _vm.signRequest.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "timeLine.ActivityTimeLineItemSignRequest.detail"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.activityLogEntry
                                    ? _c("activity-time-line-item-delete", {
                                        attrs: {
                                          text: true,
                                          partnerId:
                                            _vm.activityLogEntry.partnerId,
                                          activityId: _vm.activityLogEntry.id
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    "x-small": "",
                                    dense: "",
                                    outlined: "",
                                    color: "info"
                                  },
                                  on: {
                                    click: _vm.copyLinkToClipboard
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "timeLine.ActivityTimeLineItemSignRequest.link"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    dense: "",
                                    outlined: "",
                                    color: "info"
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.toDetail(
                                        _vm.signRequest.partnerId,
                                        _vm.signRequest.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "timeLine.ActivityTimeLineItemSignRequest.detail"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.activityLogEntry
                                ? _c("activity-time-line-item-delete", {
                                    staticClass: "mb-n1",
                                    attrs: {
                                      partnerId: _vm.activityLogEntry.partnerId,
                                      activityId: _vm.activityLogEntry.id
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-1"
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "ml-1 mr-1"
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.signRequest.title
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("report-document-card-status-menu", {
                                        attrs: {
                                          signRequest: _vm.signRequest
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.images && _vm.images.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mt-n4",
                                  attrs: {
                                    cols: "12"
                                  }
                                },
                                [
                                  _c("vue-picture-swipe", {
                                    ref: "pictureSwipe",
                                    staticClass: "mx-2 mt-4 mb-2",
                                    attrs: {
                                      items: _vm.images,
                                      options: {
                                        shareEl: false,
                                        rotationOn: true,
                                        showAnimationDuration: 0,
                                        hideAnimationDuration: 0
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }