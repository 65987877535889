var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-hover", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function fn(_ref) {
            var hover = _ref.hover
            return [
              _c(
                "v-list-item",
                {
                  style: !_vm.isOpen ? "opacity: 0.6;" : ""
                },
                [
                  _vm._t("icon"),
                  _vm.hasActionListener()
                    ? _c("v-list-item-action", [_vm._t("action")], 2)
                    : _vm._e(),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "text-xs"
                        },
                        [
                          !_vm.hasActionListener()
                            ? _c(
                                "tooltip",
                                {
                                  attrs: {
                                    text: _vm.$t(
                                      "partner.PartnerReportDetailChangeProgress." +
                                        _vm.item.progressStatus
                                    )
                                  }
                                },
                                [
                                  _c("v-avatar", {
                                    staticClass: "mr-1 white--text",
                                    attrs: {
                                      color: _vm.color,
                                      elevation: 0,
                                      size: "8"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("span", [_vm._v(" " + _vm._s(_vm.date) + " ")])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "text-truncate ",
                          class: _vm.isClosed
                            ? "text-decoration-line-through"
                            : ""
                        },
                        [
                          _vm.hasOpenDetailListener()
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function click($event) {
                                      $event.stopPropagation()
                                      return _vm.emitOpenDetail.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.item.numberplate) + " "
                                  ),
                                  _vm.item.externalId
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.item.externalId) +
                                            ")"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "text-truncate text-xs"
                        },
                        [
                          _vm._l(_vm.item.damage, function(d) {
                            return _c(
                              "span",
                              {
                                key: _vm.item.id + d
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.damages.".concat(
                                          d
                                        )
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          }),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.item.message) + " ")
                          ]),
                          _vm.imageCount
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "x-small": ""
                                      }
                                    },
                                    [_vm._v("mdi-image-multiple")]
                                  ),
                                  _vm._v(" " + _vm._s(_vm.imageCount) + " ")
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "text-truncate text-xs"
                        },
                        [
                          _vm.item.customerName
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.item.customerName) + " "
                                )
                              ])
                            : _vm._e(),
                          _vm.item.companyName
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.item.companyName) + " ")
                              ])
                            : _vm._e(),
                          _vm.item.customerContact
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "x-small": ""
                                      }
                                    },
                                    [_vm._v("mdi-phone")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.item.customerContact.phone) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.item.customerContact
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "x-small": ""
                                      }
                                    },
                                    [_vm._v("mdi-email")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.item.customerContact.email) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.UPDATE,
                        a: _vm.ResourceEnum.REPORT,
                        field: _vm.partnerId
                      }
                    },
                    [
                      _vm.confirmable
                        ? _c(
                            "v-list-item-action",
                            {
                              staticClass: "mx-0 px-0"
                            },
                            [
                              _vm.isConfirmable
                                ? _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t(
                                          "common.BackendResourceEnum.click.tooltip.report"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        [
                                          hover || _vm.confirmablePersistant
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "success",
                                                    elevation: "0",
                                                    small: "",
                                                    loading: _vm.loadingConfirm
                                                  },
                                                  on: {
                                                    click: _vm.confirm
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.BackendResourceEnum.click.report"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pl-1",
                                                      attrs: {
                                                        small: ""
                                                      }
                                                    },
                                                    [_vm._v(" mdi-check ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }