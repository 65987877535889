var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.report.summary
        ? _c(
            "v-timeline-item",
            {
              staticClass: "mb-4",
              attrs: {
                "fill-dot": "",
                color: _vm.color,
                large: ""
              },
              scopedSlots: _vm._u(
                [
                  _vm.report.user
                    ? {
                        key: "icon",
                        fn: function fn() {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "white--text"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.report.user.firstName[0] +
                                        _vm.report.user.lastName[0]
                                    )
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    : {
                        key: "icon",
                        fn: function fn() {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "white--text"
                              },
                              [_vm._v(" " + _vm._s(_vm.customerNameInitial))]
                            )
                          ]
                        },
                        proxy: true
                      }
                ],
                null,
                true
              )
            },
            [
              _c("v-hover", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function fn(_ref) {
                        var hover = _ref.hover
                        return [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto",
                              attrs: {
                                flat: "",
                                outlined: ""
                              }
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: "",
                                        color: _vm.color
                                      }
                                    },
                                    [_vm._v(" mdi-creation ")]
                                  ),
                                  _vm._v(
                                    " " + _vm._s(_vm.report.summary) + " "
                                  ),
                                  hover
                                    ? _c(
                                        "v-fab-transition",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                elevation: "0",
                                                absolute: "",
                                                fab: "",
                                                top: "",
                                                right: "",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.thumbs(false)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: _vm.color
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-thumb-down-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  hover
                                    ? _c(
                                        "v-fab-transition",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-8",
                                              attrs: {
                                                elevation: "0",
                                                absolute: "",
                                                fab: "",
                                                top: "",
                                                right: "",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.thumbs(true)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: _vm.color
                                                  }
                                                },
                                                [_vm._v("mdi-thumb-up-outline")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3677791501
                )
              })
            ],
            1
          )
        : _vm.report.user
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                "fill-dot": "",
                color: _vm.color,
                large: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function fn() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "white--text"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.report.user.firstName[0] +
                                  _vm.report.user.lastName[0]
                              )
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(_vm.report.user.firstName) +
                    " " +
                    _vm._s(_vm.report.user.lastName) +
                    " "
                )
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "timeLine.PartnerReportDetailInfoTimeLineCard.title",
                      {
                        date: _vm.date
                      }
                    )
                  ) +
                  " "
              )
            ]
          )
        : _c(
            "v-timeline-item",
            {
              attrs: {
                "fill-dot": "",
                color: _vm.color,
                large: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function fn() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "white--text"
                        },
                        [_vm._v(" " + _vm._s(_vm.customerNameInitial))]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("b", [_vm._v(" " + _vm._s(_vm.report.customerName) + " ")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "timeLine.PartnerReportDetailInfoTimeLineCard.title",
                      {
                        date: _vm.date
                      }
                    )
                  ) +
                  " "
              )
            ]
          ),
      _vm.report.customerName ||
      _vm.report.isCompany ||
      _vm.report.companyName ||
      _vm.report.isTaxDeductible ||
      _vm.report.taxnumber ||
      _vm.report.customerContact ||
      _vm.report.customerAddress
        ? _c("customer-contact-time-line-element", {
            attrs: {
              customerName: _vm.report.customerName,
              isTaxDeductible: _vm.report.isTaxDeductible,
              companyName: _vm.report.companyName,
              isCompany: _vm.report.isCompany,
              taxnumber: _vm.report.taxnumber,
              email: _vm.report.customerContact
                ? _vm.report.customerContact.email
                : undefined,
              phone: _vm.report.customerContact
                ? _vm.report.customerContact.phone
                : undefined,
              address: _vm.report.customerAddress
            }
          })
        : _vm._e(),
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.READ,
            a: _vm.ResourceEnum.VEHICLE
          }
        },
        [
          _c("vehicle-time-line-element", {
            key: "vehicle-time-line-element" + _vm.report.vehicleId,
            attrs: {
              report: _vm.report
            }
          })
        ],
        1
      ),
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.READ,
            a: _vm.ResourceEnum.HANDOVER
          }
        },
        [
          _c("handover-time-line-element", {
            key: "handover-time-line-element" + _vm.report.vehicleId,
            attrs: {
              report: _vm.report
            }
          })
        ],
        1
      ),
      _vm.report.policeDetails
        ? _c("report-police-details-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _vm.report.preExistingDamageDetails
        ? _c("report-pre-existing-damage-details-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _c("report-damage-location-time-line-element", {
        attrs: {
          report: _vm.report,
          color: _vm.color,
          clickedColor: _vm.color === "primary" ? undefined : _vm.color
        }
      }),
      _vm.report.datePreference
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                small: "",
                icon: "mdi-calendar-star",
                "fill-dot": ""
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "timeLine.PartnerReportDetailInfoTimeLineCard.dateRequest",
                      {
                        name: _vm.report.customerName,
                        date: _vm.datePreference
                      }
                    )
                  ) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _vm.report.message
        ? _c(
            "timeline-card",
            {
              attrs: {
                title: _vm.$t(
                  "timeLine.PartnerReportDetailInfoTimeLineCard.message",
                  {
                    user: _vm.report.customerName
                  }
                )
              },
              scopedSlots: _vm._u(
                [
                  _vm.canUpdateReport
                    ? {
                        key: "menu",
                        fn: function fn() {
                          return [
                            _c("partner-report-update-report-dialog", {
                              attrs: {
                                config: [_vm.ReportUpdateTabEnum.message],
                                activityTarget: "message"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            },
            [
              _c("p", {
                staticClass: "pt-3 px-4",
                domProps: {
                  textContent: _vm._s(_vm.report.message)
                }
              })
            ]
          )
        : _vm._e(),
      _vm.report.accidentDetails
        ? _c("partner-accident-details-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _c("partner-report-detail-image-time-line-element", {
        attrs: {
          images: _vm.getImages,
          report: _vm.report
        }
      }),
      _vm.report.insurances && _vm.report.insurances.length
        ? _c("partner-insurance-details-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _vm.report.registrationResults && _vm.partner.partnerType !== "train"
        ? _c("partner-registration-time-line-element", {
            attrs: {
              report: _vm.report,
              loading: _vm.registrationUpdateLoading
            },
            on: {
              update: _vm.updateRegistration
            }
          })
        : _vm._e(),
      _vm.witness.length
        ? _c("partner-report-detail-info-witness-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _vm.report.leasing
        ? _c("partner-report-detail-info-leasing-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _c("partner-report-detail-info-body-time-line-element", {
        attrs: {
          report: _vm.report
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }