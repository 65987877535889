var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      !_vm.isMenu
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex"
              }
            },
            [
              _c("file-input", {
                attrs: {
                  outlined: true,
                  value: _vm.newFile,
                  showSize: "true",
                  accept: "application/pdf, .pdf",
                  label: _vm.$t("sign.SignDocumentForm.file") + " *",
                  placeholder: _vm.$t("sign.SignDocumentForm.placeholder"),
                  loading: _vm.loading || _vm.isCreatingNewPdf
                },
                on: {
                  "update:value": function updateValue($event) {
                    _vm.newFile = $event
                  }
                }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mb-n2 mt-2 mr-n3 ml-3",
                  attrs: {
                    disabled: !_vm.newFile,
                    elevation: 0,
                    loading: _vm.isCreatingNewPdf
                  },
                  on: {
                    click: _vm.add
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticStyle: {
                display: "flex"
              }
            },
            [
              _c("v-spacer"),
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-click": false,
                    "close-on-content-click": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref) {
                        var on = _ref.on,
                          attrs = _ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-2 mb-n1 mt-2",
                                  attrs: {
                                    "x-small": "",
                                    text: "",
                                    elevation: 0,
                                    loading: _vm.isCreatingNewPdf
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("refs.addAttachment")
                                    )
                                  },
                                  on: {
                                    click: function click($event) {
                                      _vm.newFile = null
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.isAddMenu,
                    callback: function callback($$v) {
                      _vm.isAddMenu = $$v
                    },
                    expression: "isAddMenu"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("file-input", {
                        staticClass: "ma-2 mt-4",
                        attrs: {
                          value: _vm.newFile,
                          showSize: "true",
                          accept: "application/pdf, .pdf",
                          label: _vm.$t("sign.SignDocumentForm.file") + " *",
                          placeholder: _vm.$t(
                            "sign.SignDocumentForm.placeholder"
                          ),
                          loading: _vm.loading || _vm.isCreatingNewPdf
                        },
                        on: {
                          "update:value": function updateValue($event) {
                            _vm.newFile = $event
                          }
                        }
                      }),
                      _c(
                        "v-card-actions",
                        {
                          staticClass: "mt-n6"
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                "x-small": "",
                                elevation: 0
                              },
                              on: {
                                click: function click($event) {
                                  _vm.isAddMenu = false
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-small": "",
                                elevation: 0,
                                color: "info"
                              },
                              on: {
                                click: _vm.add
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm._l(_vm.pdfs, function(pdf, index) {
        return _c(
          "v-card",
          {
            key: "pdf" + pdf.id,
            staticClass: "ma-2",
            attrs: {
              outlined: ""
            }
          },
          [
            _c("v-hover", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function fn(_ref2) {
                      var hover = _ref2.hover
                      return [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-action",
                              {
                                staticClass: "mr-1"
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-5"
                                  },
                                  [_vm._v("mdi-paperclip")]
                                )
                              ],
                              1
                            ),
                            _c("refs-document", {
                              attrs: {
                                item: {
                                  title: pdf.name,
                                  description: _vm.simpleDoubleDigitDate(
                                    pdf.timestamp.created
                                  ),
                                  url: pdf.url
                                }
                              }
                            }),
                            _c(
                              "v-list-item-action",
                              {
                                staticClass: "mx-1 px-0"
                              },
                              [
                                _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      text: _vm.$t("remove")
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "mr-2"
                                      },
                                      [
                                        hover
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  small: "",
                                                  color: "grey",
                                                  loading: _vm.isCreatingNewPdf
                                                },
                                                on: {
                                                  click: function click(
                                                    $event
                                                  ) {
                                                    return _vm.remove(index)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: ""
                                                    }
                                                  },
                                                  [_vm._v(" mdi-close ")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }