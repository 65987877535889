var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("time-line-item-text", {
    attrs: {
      color: "info",
      icon: "mdi-email",
      text: _vm.title
    },
    scopedSlots: _vm._u([
      {
        key: "menu",
        fn: function fn() {
          return [
            _vm.deleteUpdateActivity
              ? _c(
                  "div",
                  [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _c(
                          "context-menu",
                          [
                            _c(
                              "v-list",
                              [
                                _vm.activityLogEntry
                                  ? _c("activity-time-line-item-delete", {
                                      attrs: {
                                        text: true,
                                        partnerId:
                                          _vm.activityLogEntry.partnerId,
                                        activityId: _vm.activityLogEntry.id
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _vm.activityLogEntry
                              ? _c("activity-time-line-item-delete", {
                                  attrs: {
                                    partnerId: _vm.activityLogEntry.partnerId,
                                    activityId: _vm.activityLogEntry.id
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }