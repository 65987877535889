var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      Object.keys(_vm.reportDto.body || []).length
        ? _c(
            "card",
            {
              attrs: {
                hideTitle: true,
                margin: 0,
                flat: "",
                outlined: ""
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "pt-2 pr-2 pl-2"
                },
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.isValid,
                        callback: function callback($$v) {
                          _vm.isValid = $$v
                        },
                        expression: "isValid"
                      }
                    },
                    _vm._l(_vm.reportDto.body || [], function(body, index) {
                      return _c(
                        "div",
                        {
                          key: "bodykey" + index
                        },
                        _vm._l(Object.keys(body), function(key, index) {
                          return _c("v-text-field", {
                            key: "bodykeykey" + index,
                            attrs: {
                              flat: "",
                              outlined: "",
                              label: key
                            },
                            model: {
                              value: body[key],
                              callback: function callback($$v) {
                                _vm.$set(body, key, $$v)
                              },
                              expression: "body[key]"
                            }
                          })
                        }),
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ]
          )
        : _c("latest-entries-card-empty")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }