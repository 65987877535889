var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        margin: 0,
        flat: "",
        outlined: "",
        hideTitle: true,
        subtitle: "Name"
      }
    },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.isValid,
            callback: function callback($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-2 pr-2 pl-2",
              attrs: {
                "no-gutters": ""
              }
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("v-text-field", {
                    class: _vm.$vuetify.breakpoint.mdAndUp ? "mr-3" : "",
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.firstName"
                      ),
                      flat: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.reportDto.firstName,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto, "firstName", $$v)
                      },
                      expression: "reportDto.firstName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("v-text-field", {
                    class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-3" : "",
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.lastName"
                      ),
                      flat: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.reportDto.lastName,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto, "lastName", $$v)
                      },
                      expression: "reportDto.lastName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("v-select", {
                    class: _vm.$vuetify.breakpoint.mdAndUp ? "mr-3" : "",
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.isCompany"
                      ),
                      flat: "",
                      outlined: "",
                      items: [true, false]
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function fn(_ref) {
                          var item = _ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item ? _vm.$t("yes") : _vm.$t("no")) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "selection",
                        fn: function fn(_ref2) {
                          var item = _ref2.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item ? _vm.$t("yes") : _vm.$t("no")) +
                                " "
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.reportDto.isCompany,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto, "isCompany", $$v)
                      },
                      expression: "reportDto.isCompany"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("v-text-field", {
                    class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-3" : "",
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.companyName"
                      ),
                      flat: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.reportDto.companyName,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto, "companyName", $$v)
                      },
                      expression: "reportDto.companyName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("v-select", {
                    class: _vm.$vuetify.breakpoint.mdAndUp ? "mr-3" : "",
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.isTaxDeductible"
                      ),
                      flat: "",
                      outlined: "",
                      items: [true, false]
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function fn(_ref3) {
                          var item = _ref3.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item ? _vm.$t("yes") : _vm.$t("no")) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "selection",
                        fn: function fn(_ref4) {
                          var item = _ref4.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item ? _vm.$t("yes") : _vm.$t("no")) +
                                " "
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.reportDto.isTaxDeductible,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto, "isTaxDeductible", $$v)
                      },
                      expression: "reportDto.isTaxDeductible"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("v-text-field", {
                    class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-3" : "",
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.taxnumber"
                      ),
                      flat: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.reportDto.taxnumber,
                      callback: function callback($$v) {
                        _vm.$set(_vm.reportDto, "taxnumber", $$v)
                      },
                      expression: "reportDto.taxnumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }