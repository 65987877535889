var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "pt-4 pr-4 pl-4",
      attrs: {
        flat: "",
        "max-width": "500"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              {
                key: index
              },
              [
                _c(
                  "v-card-text",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("report.insurance.itemTitle", {
                            number: index + 1
                          })
                        ) +
                        " "
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-2",
                        attrs: {
                          elevation: "0",
                          small: "",
                          color: "error",
                          "data-test-remove-insurance": ""
                        },
                        on: {
                          click: function click($event) {
                            return _vm.remove(index)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("report.insurance.remove")) + " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("insurance-form", {
                  attrs: {
                    value: item
                  },
                  on: {
                    input: function input($event) {
                      item = item
                    }
                  }
                }),
                _c("v-divider", {
                  staticClass: "mt-2 mb-4"
                })
              ],
              1
            )
          }),
          !_vm.list.length
            ? _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "0",
                    color: "success",
                    "data-test-add-insurance": ""
                  },
                  on: {
                    click: _vm.add
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("report.insurance.add")) + " ")]
              )
            : _c(
                "span",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        elevation: "0",
                        color: "success",
                        "data-test-add-another-insurance": ""
                      },
                      on: {
                        click: _vm.add
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("report.insurance.addFurther"))
                      )
                    ]
                  )
                ],
                1
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }