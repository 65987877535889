var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("v-text-field", {
        staticClass: "mb-n7",
        attrs: {
          label: _vm.$t("common.nouns.search"),
          dense: "",
          outlined: "",
          "append-icon": "mdi-magnify"
        },
        on: {
          input: _vm.debounceSetSearchFilter
        },
        model: {
          value: _vm.searchFilter,
          callback: function callback($$v) {
            _vm.searchFilter = $$v
          },
          expression: "searchFilter"
        }
      }),
      _vm.suggestedSorted.length
        ? _c("virtual-list", {
            staticStyle: {
              "max-height": "500px",
              overflow: "auto"
            },
            attrs: {
              "data-component": {},
              "data-sources": _vm.suggestedSorted,
              "data-key": "id"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            outlined: "",
                            isLoading: _vm.isLoading
                          }
                        },
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.select(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                {
                                  staticClass: "mr-n2"
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.RefTypeMap.get(item.refType).icon
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass: "pa-0"
                                },
                                [
                                  item.ref
                                    ? _c("refs-list", {
                                        attrs: {
                                          item: item.ref,
                                          refType: item.refType,
                                          small: true
                                        },
                                        on: {
                                          openDetail: function openDetail(
                                            $event
                                          ) {
                                            return _vm.select(item)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("v-list-item-icon", [_c("a-eicon")], 1)
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              4134550509
            )
          })
        : _c(
            "v-card",
            {
              attrs: {
                outlined: ""
              }
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("a-eicon", {
                        attrs: {
                          small: false
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.loading
                    ? _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "text-wrap"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("suggestions.noSuggestionsFound")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            {
                              staticClass: "text-wrap"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "suggestions.noSuggestionsFoundAdvice"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "text-wrap"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("suggestions.loading")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            {
                              staticClass: "text-wrap"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("suggestions.loadingDetail")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }