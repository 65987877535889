var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-chip",
    {
      attrs: {
        color: "info"
      },
      on: {
        click: function click($event) {
          return _vm.goToDetail(_vm.reference)
        }
      }
    },
    [
      _c(
        "tooltip",
        {
          attrs: {
            text: _vm.itemText
          }
        },
        [
          _c("v-icon", [
            _vm._v(" " + _vm._s(_vm.getIcon(_vm.reference.refType)))
          ]),
          _vm._v(" " + _vm._s(_vm.itemShortText) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }