var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        fullscreen: _vm.fullscreenBreakpoint,
        absolute: true,
        width: !_vm.fullscreenBreakpoint ? "1200px" : "100%",
        title: _vm.$t("views.report.my.MyDamageView.title"),
        subtitle: _vm.$t("views.report.my.MyDamageView.subtitle", {
          date: _vm.creationDate
        }),
        loading: _vm.loading
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      }
    },
    [_c("my-damage-detail")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }