import { render, staticRenderFns } from "./Report.vue?vue&type=template&id=4ea45bc8&"
import script from "./Report.vue?vue&type=script&lang=ts&"
export * from "./Report.vue?vue&type=script&lang=ts&"
import style0 from "./Report.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ea45bc8')) {
      api.createRecord('4ea45bc8', component.options)
    } else {
      api.reload('4ea45bc8', component.options)
    }
    module.hot.accept("./Report.vue?vue&type=template&id=4ea45bc8&", function () {
      api.rerender('4ea45bc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/Report.vue"
export default component.exports