var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card-text",
        {
          staticClass: "pb-0"
        },
        [
          _c("template-editor", {
            ref: "templateEditor",
            attrs: {
              readOnly: false,
              partnerId: _vm.id,
              label: _vm.$t("components.template.detail.body.body"),
              isMobile: _vm.isEditorMobile
            },
            model: {
              value: _vm.comment,
              callback: function callback($$v) {
                _vm.comment = $$v
              },
              expression: "comment"
            }
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "mt-n8 px-4"
        },
        [
          _vm.report && _vm.report.progressStatus != _vm.finished
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "grey-lighten-4",
                    small: "",
                    elevation: "0",
                    disabled: !_vm.comment
                  },
                  on: {
                    click: function click($event) {
                      return _vm.closeAndAddComment()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.template.detail.body.closeAndSend")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                disabled: !_vm.comment,
                loading: _vm.isLoading,
                small: "",
                elevation: "0"
              },
              on: {
                click: function click($event) {
                  return _vm.addComment()
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("components.template.detail.body.comment")) +
                  " "
              )
            ]
          ),
          _vm._t("actions")
        ],
        2
      ),
      _c("confirm-action-dialog", {
        attrs: {
          title: _vm.$t(
            "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixedQuestion"
          ),
          subtitle: _vm.$t(
            "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixedQuestionDetail"
          ),
          isDialogActive: _vm.isIsFixedDialog,
          leftText: _vm.$t("no"),
          rightText: _vm.$t("yes"),
          rightLoading: _vm.isIsFixedLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isIsFixedDialog = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isIsFixedDialog = $event
          },
          close: function close($event) {
            _vm.isIsFixedDialog = false
          },
          leftClick: function leftClick($event) {
            _vm.isIsFixedDialog = false
          },
          rightClick: _vm.markFixed
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }