var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("time-line-item-text", {
    attrs: {
      color: _vm.statusConfiguration.color,
      icon: _vm.statusConfiguration.icon,
      text: "<b>"
        .concat(_vm.name, " </b> ")
        .concat(
          _vm.$t("timeLine.ActivityTimeLineItemReportStatusUpdate.title", {
            date: _vm.date
          }),
          " "
        )
        .concat(_vm.statusConfiguration.text)
    },
    scopedSlots: _vm._u([
      {
        key: "menu",
        fn: function fn() {
          return [
            _vm.deleteUpdateActivity
              ? _c(
                  "div",
                  [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _c(
                          "div",
                          {
                            staticClass: "mr-4"
                          },
                          [
                            _c(
                              "context-menu",
                              [
                                _c("activity-time-line-item-delete", {
                                  attrs: {
                                    text: true,
                                    partnerId: _vm.activityLogEntry.partnerId,
                                    activityId: _vm.activityLogEntry.id
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c("activity-time-line-item-delete", {
                          staticClass: "mr-4",
                          attrs: {
                            partnerId: _vm.activityLogEntry.partnerId,
                            activityId: _vm.activityLogEntry.id
                          }
                        })
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }