var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        width: "1000px",
        loading: _vm.isLoading,
        absolute: true,
        hideTitle: true
      },
      on: {
        close: _vm.close
      }
    },
    [
      _vm.isLoading
        ? _c("v-skeleton-loader", {
            attrs: {
              type: "list-item-two-line"
            }
          })
        : _c("shared-content-detail", {
            staticClass: "ma-1 pa-1",
            attrs: {
              value: _vm.value,
              suggested: _vm.suggestedDocuments,
              hideBreadCrumbs: true
            },
            on: {
              onDelete: _vm.close
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }