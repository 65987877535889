var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "timeline-card",
    {
      attrs: {
        loading: _vm.isLoading,
        title: _vm.title
      },
      scopedSlots: _vm._u([
        {
          key: "menu",
          fn: function fn() {
            return [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "context-menu",
                    [
                      _c(
                        "v-list",
                        [
                          _vm.downloads
                            ? _c("file-download", {
                                attrs: {
                                  downloadFiles: _vm.downloads,
                                  listItem: true
                                }
                              })
                            : _vm._e(),
                          _vm.activityLogEntry
                            ? _c("activity-time-line-item-delete", {
                                attrs: {
                                  text: true,
                                  partnerId: _vm.activityLogEntry.partnerId,
                                  activityId: _vm.activityLogEntry.id
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center"
                      }
                    },
                    [
                      _vm.downloads
                        ? _c("file-download", {
                            attrs: {
                              downloadFiles: _vm.downloads
                            }
                          })
                        : _vm._e(),
                      _vm.activityLogEntry
                        ? _c("activity-time-line-item-delete", {
                            attrs: {
                              partnerId: _vm.activityLogEntry.partnerId,
                              activityId: _vm.activityLogEntry.id
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isLoading
        ? _c("v-skeleton-loader", {
            attrs: {
              type: "image"
            }
          })
        : _c("vue-picture-swipe", {
            attrs: {
              items: _vm.items,
              options: {
                shareEl: false,
                rotationOn: true
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }