var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.icon
        ? _c(
            "v-btn",
            {
              attrs: {
                loading: _vm.loading,
                icon: ""
              },
              on: {
                click: _vm.resetDialog
              }
            },
            [
              _c(
                "v-icon",
                {
                  attrs: {
                    color: "grey lighten-1"
                  }
                },
                [_vm._v("mdi-pencil")]
              )
            ],
            1
          )
        : _c(
            "v-btn",
            {
              attrs: {
                loading: _vm.loading,
                elevation: "0",
                "x-small": "",
                dense: "",
                color: "info"
              },
              on: {
                click: _vm.resetDialog
              }
            },
            [_vm._v(" Bearbeiten ")]
          ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            title: "Fahrzeugschein bearbeiten",
            fullscreen: true,
            isDialogActive: _vm.dialog,
            rightDisabled: _vm.isDisabled,
            rightLoading: _vm.loading,
            rightTextLocal: "Speichern"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            leftClick: _vm.close,
            rightClick: _vm.updateRegistration
          }
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        dense: ""
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "6",
                            md: "5",
                            lg: "4"
                          }
                        },
                        [
                          _c("registration-form", {
                            model: {
                              value: _vm.registrationModel,
                              callback: function callback($$v) {
                                _vm.registrationModel = $$v
                              },
                              expression: "registrationModel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "6",
                            md: "7",
                            lg: "8"
                          }
                        },
                        [
                          _c("v-img", {
                            staticClass: "ma-6",
                            attrs: {
                              contain: "",
                              "max-height": "700",
                              src: _vm.registrationImageLink,
                              "lazy-src": require("@/assets/registration_form_schema.svg")
                            },
                            on: {
                              click: _vm.zoomImage
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }