var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-timeline-item",
    {
      staticClass: "pt-6 mb-4",
      attrs: {
        icon: _vm.icon,
        "fill-dot": "",
        color: _vm.color,
        small: ""
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center"
          }
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.text)
            }
          }),
          _vm._t("menu")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }