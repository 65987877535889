var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      !_vm.hideButton
        ? _c(
            "v-btn",
            {
              attrs: {
                "x-small": "",
                elevation: "0",
                color: "info"
              },
              on: {
                click: _vm.startDialog
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.button"
                    )
                  ) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _vm.isDialogActive
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                supressKeyboardActions: true,
                isDialogActive: _vm.isDialogActive,
                fullscreen: _vm.fullscreen,
                title: _vm.$t(
                  "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.title"
                ),
                hideLeft: true,
                rightDisabled: !_vm.isValid,
                rightText: _vm.$t("update"),
                rightLoading: _vm.updateLoading,
                width: _vm.width,
                cardClass: "px-0"
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDialogActive = $event
                },
                rightClick: _vm.update
              }
            },
            [
              _c("create-report-form", {
                attrs: {
                  active: {
                    position: _vm.isPosition,
                    report: _vm.isReport,
                    contact: _vm.isContact,
                    witness: _vm.isWitness,
                    police: _vm.isPoliceDetails,
                    leasing: _vm.isLeasingDetails,
                    accident: _vm.isAccidentDetails,
                    body: _vm.isBody,
                    message: _vm.isMessage,
                    preExisting: _vm.isPreExisting,
                    insurance: _vm.isInsurance,
                    registration: false,
                    images: _vm.isImages
                  },
                  dto: _vm.updateReportDto
                },
                on: {
                  "update:dto": function updateDto($event) {
                    _vm.updateReportDto = $event
                  }
                },
                model: {
                  value: _vm.isValid,
                  callback: function callback($$v) {
                    _vm.isValid = $$v
                  },
                  expression: "isValid"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }