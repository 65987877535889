var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "draggable",
    {
      attrs: {
        list: _vm.screenSelection,
        handle: ".handle"
      }
    },
    _vm._l(_vm.screenSelection, function(screen, index) {
      return _c("v-hover", {
        key: screen + index,
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function fn(_ref) {
                var hover = _ref.hover
                return [
                  _c(
                    "div",
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "mt-n2 mb-n2"
                        },
                        [
                          !_vm.readonly
                            ? _c(
                                "v-list-item-action",
                                [
                                  hover || _vm.isMobile
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "handle"
                                        },
                                        [_vm._v(" mdi-drag-vertical ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "screenEnum.".concat(screen, ".title")
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "screenEnum.".concat(
                                          screen,
                                          ".description"
                                        )
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-action", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  direction: "row"
                                }
                              },
                              [
                                _vm.defaultPartnerUrl
                                  ? _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "open-on-hover": "",
                                          top: "",
                                          left: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function fn(_ref2) {
                                                var on = _ref2.on,
                                                  attrs = _ref2.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: ""
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " mdi-eye-outline "
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("v-card", [
                                          _c("iframe", {
                                            attrs: {
                                              width: "350px",
                                              height: "600px",
                                              src: "https://"
                                                .concat(
                                                  _vm.defaultPartnerUrl,
                                                  "/#/"
                                                )
                                                .concat(screen)
                                            }
                                          })
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.readonly
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: ""
                                        },
                                        on: {
                                          click: function click($event) {
                                            return _vm.screenSelection.splice(
                                              index,
                                              1
                                            )
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v(" mdi-minus ")])],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      index !== _vm.screenSelection.length - 1
                        ? _c("v-divider")
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }