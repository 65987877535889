var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      key: "address" + _vm.key,
      attrs: {
        margin: 0,
        flat: "",
        outlined: "",
        hideTitle: true,
        subtitle: _vm.$t(
          "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.title.customerAddress"
        )
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.reportDto.customerAddress
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        "data-test-address-add-btn": ""
                      },
                      on: {
                        click: _vm.add
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                  )
                : _vm._e(),
              _vm.showRemove
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        "data-test-address-close-btn": ""
                      },
                      on: {
                        click: _vm.remove
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.reportDto.customerAddress
        ? _c(
            "div",
            {
              staticClass: "pt-2 pr-2 pl-2"
            },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.street"
                              ),
                              flat: "",
                              outlined: "",
                              rules: _vm.requiredRule,
                              "data-test-street": ""
                            },
                            model: {
                              value: _vm.reportDto.customerAddress.street,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.reportDto.customerAddress,
                                  "street",
                                  $$v
                                )
                              },
                              expression: "reportDto.customerAddress.street"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.city"
                              ),
                              flat: "",
                              outlined: "",
                              rules: _vm.requiredRule,
                              "data-test-city": ""
                            },
                            model: {
                              value: _vm.reportDto.customerAddress.city,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.reportDto.customerAddress,
                                  "city",
                                  $$v
                                )
                              },
                              expression: "reportDto.customerAddress.city"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.zip"
                              ),
                              flat: "",
                              outlined: "",
                              rules: _vm.requiredRule,
                              "data-test-zip": ""
                            },
                            model: {
                              value: _vm.reportDto.customerAddress.zip,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.reportDto.customerAddress,
                                  "zip",
                                  $$v
                                )
                              },
                              expression: "reportDto.customerAddress.zip"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.state"
                              ),
                              flat: "",
                              outlined: "",
                              "data-test-state": ""
                            },
                            model: {
                              value: _vm.reportDto.customerAddress.state,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.reportDto.customerAddress,
                                  "state",
                                  $$v
                                )
                              },
                              expression: "reportDto.customerAddress.state"
                            }
                          }),
                          _c("v-combobox", {
                            attrs: {
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.countryCode"
                              ),
                              flat: "",
                              outlined: "",
                              items: _vm.countryCodeItems,
                              "data-test-country": ""
                            },
                            model: {
                              value: _vm.reportDto.customerAddress.countryCode,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.reportDto.customerAddress,
                                  "countryCode",
                                  $$v
                                )
                              },
                              expression:
                                "reportDto.customerAddress.countryCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }