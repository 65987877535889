var render = function() {
  var _vm$partner$settings$

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        isProgressDone: true
      }
    },
    [
      _vm.isLoading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: {
                  type:
                    "card-heading, list-item-two-line, image, card-heading, list-item-two-line, article, actions"
                }
              })
            ],
            1
          )
        : _c(
            "v-container",
            {
              staticClass: "pb-14"
            },
            [
              !_vm.isError
                ? _c(
                    "v-card",
                    {
                      staticClass: "card-outer",
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.signRequest.title))
                      ]),
                      _vm.signRequest.description
                        ? _c("v-card-text", [
                            _vm._v(
                              " " + _vm._s(_vm.signRequest.description) + " "
                            )
                          ])
                        : _c("v-card-text", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("sign.Sign.description", {
                                    companyName: _vm.partner.companyName
                                  })
                                ) +
                                " "
                            )
                          ]),
                      _vm.signRequest.documents
                        ? _c(
                            "v-row",
                            {
                              staticClass: "pt-8"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    md: "7"
                                  }
                                },
                                _vm._l(_vm.signRequest.documents, function(
                                  document
                                ) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: document
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12"
                                          }
                                        },
                                        [
                                          _c("iframe", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.isPdfLoaded,
                                                expression: "isPdfLoaded"
                                              }
                                            ],
                                            ref: "pdf",
                                            refInFor: true,
                                            staticClass: "pdf",
                                            style: "border: none; width:100%; height: ".concat(
                                              _vm.heightIframe
                                            ),
                                            attrs: {
                                              title: "pdf",
                                              src: document,
                                              id: "pdf"
                                            },
                                            on: {
                                              click: document,
                                              load: _vm.load
                                            }
                                          }),
                                          !_vm.isPdfLoaded
                                            ? _c("v-skeleton-loader", {
                                                staticClass: "mx-auto",
                                                attrs: {
                                                  type: "image"
                                                }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                "nudge-right": ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function fn(_ref) {
                                                      var on = _ref.on
                                                      return [
                                                        _vm.$vuetify.breakpoint
                                                          .mobile
                                                          ? _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    inverted:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    href: document,
                                                                    target:
                                                                      "_blank",
                                                                    block: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "sign.Sign.openFile"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-download"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    icon: "",
                                                                    href: document,
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-download"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("sign.Sign.download")
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    md: "5"
                                  }
                                },
                                [
                                  !_vm.isLoading
                                    ? _c(
                                        "v-form",
                                        {
                                          ref: "signDocumentForm",
                                          model: {
                                            value: _vm.isValid,
                                            callback: function callback($$v) {
                                              _vm.isValid = $$v
                                            },
                                            expression: "isValid"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-card-text", {
                                                staticClass: "pl-0 pt-2",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t("sign.Sign.terms", {
                                                      agb:
                                                        ((_vm$partner$settings$ =
                                                          _vm.partner.settings
                                                            .agbUrl) === null ||
                                                        _vm$partner$settings$ ===
                                                          void 0
                                                          ? void 0
                                                          : _vm$partner$settings$.link) ||
                                                        "/#/agbs/"
                                                    })
                                                  )
                                                }
                                              }),
                                              _c(
                                                "v-col",
                                                {
                                                  class:
                                                    _vm.firstNameSmallClass,
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "6",
                                                    md: "12",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "sign.Sign.firstName"
                                                      ),
                                                      rules: _vm.requiredRule
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.signature.firstName,
                                                      callback: function callback(
                                                        $$v
                                                      ) {
                                                        _vm.$set(
                                                          _vm.signature,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "signature.firstName"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "6",
                                                    md: "12",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "sign.Sign.lastName"
                                                      ),
                                                      rules: _vm.requiredRule
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.signature.lastName,
                                                      callback: function callback(
                                                        $$v
                                                      ) {
                                                        _vm.$set(
                                                          _vm.signature,
                                                          "lastName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "signature.lastName"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0 mt-n8",
                                                  attrs: {
                                                    cols: "12"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "pa-1 overlay",
                                                      style: _vm.overlayStyle
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "sign.Sign.signOverlay"
                                                            )
                                                          )
                                                      )
                                                    ]
                                                  ),
                                                  _c("vue-signature-pad", {
                                                    ref: "signaturePad",
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      id: "signature",
                                                      width: "100%",
                                                      height:
                                                        _vm.heightSignature
                                                    }
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "signLine unselectable"
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "pt-4 pl-4 pr-4"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "pt-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "simpleDoubleDigitDate"
                                                                )(new Date())
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            left: "",
                                                            "nudge-left": ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function fn(
                                                                  _ref2
                                                                ) {
                                                                  var on =
                                                                    _ref2.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                            icon:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.clear
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-delete-outline"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            1505562406
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "sign.Sign.clear"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-checkbox", {
                                                    ref: "terms",
                                                    attrs: {
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "sign.Sign.confirmationTerms"
                                                      ),
                                                      rules: [
                                                        function(v) {
                                                          return (
                                                            !!v ||
                                                            _vm.$t(
                                                              "sign.Sign.readTerms"
                                                            )
                                                          )
                                                        }
                                                      ],
                                                      "data-test-terms-checkbox":
                                                        ""
                                                    },
                                                    model: {
                                                      value: _vm.terms,
                                                      callback: function callback(
                                                        $$v
                                                      ) {
                                                        _vm.terms = $$v
                                                      },
                                                      expression: "terms"
                                                    }
                                                  }),
                                                  _c("v-checkbox", {
                                                    ref: "withdrawal",
                                                    attrs: {
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "sign.Sign.confirmationWithdrawal"
                                                      ),
                                                      rules: [
                                                        function(v) {
                                                          return (
                                                            !!v ||
                                                            _vm.$t(
                                                              "sign.Sign.readWithdrawal"
                                                            )
                                                          )
                                                        }
                                                      ],
                                                      "data-test-withdrawal-checkbox":
                                                        ""
                                                    },
                                                    model: {
                                                      value: _vm.withdrawal,
                                                      callback: function callback(
                                                        $$v
                                                      ) {
                                                        _vm.withdrawal = $$v
                                                      },
                                                      expression: "withdrawal"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: !_vm.isValid,
                                        color: "success",
                                        block: "",
                                        "x-large": "",
                                        "min-width": "250px",
                                        loading: _vm.isSaving
                                      },
                                      on: {
                                        click: _vm.send
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("sign.Sign.submit")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("debug", {
                        attrs: {
                          debug: _vm.signRequest
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "justify-center"
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.errorTitle)) + " ")]
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                "nudge-right": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function fn(_ref3) {
                                    var on = _ref3.on
                                    return [
                                      _c(
                                        "v-img",
                                        _vm._g(
                                          {
                                            staticClass: "mx-auto",
                                            attrs: {
                                              src: require("@/assets/undraw/undraw_authentication_fsn5.svg"),
                                              height: "300",
                                              contain: ""
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("sign.Sign.noData")))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        {
                          staticClass: "justify-center"
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:" + _vm.partner.settings.contact.email
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("sign.Sign.error.message", {
                                      companyName: _vm.partner.companyName
                                    })
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }