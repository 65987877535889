var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("card", {
    attrs: {
      title: _vm.title,
      minWidth: 150
    },
    scopedSlots: _vm._u([
      {
        key: "main",
        fn: function fn() {
          return _vm._l(Object.keys(_vm.meta.meta), function(metaKey) {
            return _c(
              "span",
              {
                key: metaKey
              },
              [
                _vm.meta.meta[metaKey]
                  ? _c("span", [
                      _c("small", [_vm._v(" " + _vm._s(metaKey) + " ")]),
                      _c("br"),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.meta.meta[metaKey]) + " ")
                      ]),
                      _c("br")
                    ])
                  : _vm._e()
              ]
            )
          })
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }