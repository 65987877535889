var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    [
      _c(
        "v-list-item-content",
        [
          _vm.job && _vm.job.vehicleManufacturer
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetail.SendToKsrDialog.vehicleManufacturer"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.job.vehicleManufacturer) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.job && _vm.job.vehicleModel
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetail.SendToKsrDialog.vehicleModel"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.job.vehicleModel) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.job && _vm.job.vehicleType
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetail.SendToKsrDialog.vehicleType"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.job.vehicleType) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.job && _vm.job.jobId
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetail.SendToKsrDialog.jobId"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.job.jobId) +
                    " "
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-list-item-content",
        [
          _vm.job && _vm.job.employee
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetail.SendToKsrDialog.employee"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.job.employee) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.job && _vm.job.vehicleArrival
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetail.SendToKsrDialog.vehicleArrival"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.getDate(_vm.job.vehicleArrival)) +
                    " "
                )
              ])
            : _vm.job && _vm.job.startOfWork
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetail.SendToKsrDialog.startOfWork"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.getDate(_vm.job.startOfWork)) +
                    " "
                )
              ])
            : _vm.job && _vm.job.plannedStartOfWork
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.partner.PartnerReportDetail.SendToKsrDialog.plannedStartOfWork"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.getDate(_vm.job.plannedStartOfWork)) +
                    " "
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }