var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _vm._l(_vm.firstAssignees, function(ass, index) {
        return _c(
          "Can",
          {
            key: "assignee" + ass,
            attrs: {
              I: _vm.ActionEnum.READ,
              a: _vm.ResourceEnum.USER
            }
          },
          [
            ass && ass.firstName && ass.lastName
              ? _c(
                  "tooltip",
                  {
                    attrs: {
                      text: _vm.getAssigneeName(ass)
                    }
                  },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass: "white--text",
                        class:
                          index !== _vm.firstAssignees.length - 1
                            ? "mr-n1"
                            : "",
                        attrs: {
                          color: "grey lighten-1",
                          small: "",
                          size: "24px"
                        }
                      },
                      [
                        _c("small", [
                          _vm._v(
                            " " + _vm._s(_vm.getAssigneeInititals(ass)) + " "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      }),
      _vm.remainingAssignees.length
        ? _c(
            "tooltip",
            {
              attrs: {
                text: _vm.remainingAssignees
                  .map(function(ass) {
                    return _vm.getAssigneeName(ass)
                  })
                  .join("<br/>")
              }
            },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "white--text ml-n1",
                  attrs: {
                    color: "grey lighten-1",
                    small: "",
                    size: "24px"
                  }
                },
                [
                  _c("small", [
                    _vm._v("+" + _vm._s(_vm.remainingAssignees.length) + " ")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }