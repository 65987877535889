var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      key: "preference" + _vm.key,
      attrs: {
        margin: 0,
        flat: "",
        outlined: "",
        subtitle: _vm.$t(
          "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationMainInspection.title"
        ),
        hideTitle: true
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.isRegistrationName
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: ""
                      },
                      on: {
                        click: _vm.add
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                  )
                : _vm._e(),
              _vm.showRemove
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: ""
                      },
                      on: {
                        click: _vm.remove
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isRegistrationName
        ? _c(
            "div",
            {
              staticClass: "pt-2 pr-2 pl-2"
            },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": ""
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pr-1",
                          attrs: {
                            cols: "12",
                            md: "6"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              type: "number",
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationMainInspection.huMonth"
                              ),
                              rules: _vm.isNumberRule
                            },
                            model: {
                              value: _vm.reportDto.registrationResults.huMonth,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.reportDto.registrationResults,
                                  "huMonth",
                                  $$v
                                )
                              },
                              expression:
                                "reportDto.registrationResults.huMonth"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-1",
                          attrs: {
                            cols: "12",
                            md: "6"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              type: "number",
                              label: _vm.$t(
                                "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationMainInspection.huYear"
                              ),
                              rules: _vm.isNumberRule
                            },
                            model: {
                              value: _vm.reportDto.registrationResults.huYear,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.reportDto.registrationResults,
                                  "huYear",
                                  $$v
                                )
                              },
                              expression: "reportDto.registrationResults.huYear"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }