var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        md: _vm.md,
        isDone: _vm.isDone,
        title: _vm.header.title,
        description: _vm.header.description,
        debug: [_vm.user, _vm.contact]
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pt-4 pl-4 pr-4",
          attrs: {
            flat: "",
            "max-width": "500"
          }
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function callback($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("report.formPersonalDataAlt.companyName"),
                  required: "",
                  "data-test-form-numberplate": "",
                  outlined: ""
                },
                model: {
                  value: _vm.companyName,
                  callback: function callback($$v) {
                    _vm.companyName = $$v
                  },
                  expression: "companyName"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("report.formPersonalData.firstName"),
                  rules: _vm.noNumberRule,
                  required: "",
                  "data-test-form-firstName": "",
                  outlined: ""
                },
                model: {
                  value: _vm.user.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "firstName", $$v)
                  },
                  expression: "user.firstName"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("report.formPersonalData.lastName"),
                  rules: _vm.noNumberRule,
                  required: "",
                  "data-test-form-lastName": "",
                  outlined: ""
                },
                model: {
                  value: _vm.user.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "lastName", $$v)
                  },
                  expression: "user.lastName"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("report.formPersonalData.phone"),
                  type: "tel",
                  rules: _vm.phoneNumberRules,
                  required: "",
                  "data-test-form-phone": "",
                  outlined: ""
                },
                model: {
                  value: _vm.contact.phone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contact, "phone", $$v)
                  },
                  expression: "contact.phone"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("report.formPersonalData.mail"),
                  type: "email",
                  rules: _vm.emailRules,
                  autocomplete: "email",
                  required: "",
                  "data-test-form-email": "",
                  outlined: ""
                },
                model: {
                  value: _vm.contact.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contact, "email", $$v)
                  },
                  expression: "contact.email"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }