var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-timeline-item",
        {
          staticClass: "mt-4",
          attrs: {
            small: "",
            icon: "mdi-account-multiple",
            color: "info",
            "fill-dot": ""
          }
        },
        [
          _c("b", [_vm._v(_vm._s(_vm.name) + " ")]),
          _vm._v(
            _vm._s(
              _vm.$t("timeLine.BaseActivityTimeLineItem.title", {
                date: _vm.date
              })
            ) + " "
          )
        ]
      ),
      _c(
        "v-card",
        {
          attrs: {
            outlined: "",
            flat: ""
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              dense: "",
              "item-key": "key",
              headers: _vm.headers,
              items: _vm.parseActivityToKeyValue(_vm.activityLogEntry),
              "hide-default-footer": "",
              "hide-default-header": "",
              "disable-pagination": ""
            }
          })
        ],
        1
      ),
      _c("v-timeline-item", {
        attrs: {
          small: "",
          icon: "mdi-camera",
          "hide-dot": ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }