var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("layout-report-with-banner", {
    attrs: {
      showFooter: false,
      isProgressDone: true
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function fn() {
          return [
            _c("notifications", {
              staticClass: "ma-1"
            }),
            _c("table-wrapper", {
              attrs: {
                headers: _vm.headers,
                allItems: _vm.items,
                loading: _vm.loading,
                sortBy: "timestamp.created",
                sortDesc: true
              },
              on: {
                "click:row": function clickRow(e) {
                  return _vm.openDetail(e)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "item.timestamp.created",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("simpleDoubleDigitDate")(
                              item.timestamp.created
                            )
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.progressStatus",
                  fn: function fn(_ref2) {
                    var item = _ref2.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            small: "",
                            color: _vm.colorByStatus.get(item.progressStatus)
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "views.report.my.MyDamageView.status.".concat(
                                    item.progressStatus
                                  )
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.numberplate",
                  fn: function fn(_ref3) {
                    var item = _ref3.item
                    return [_vm._v(" " + _vm._s(item.numberplate) + " ")]
                  }
                },
                {
                  key: "item.damage",
                  fn: function fn(_ref4) {
                    var item = _ref4.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.damage
                              .map(function(damage) {
                                return _vm.$t(
                                  "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.damages.".concat(
                                    damage
                                  )
                                )
                              })
                              .join(", ")
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm.selectedId
              ? _c("my-damage-detail-side-card", {
                  attrs: {
                    loading: _vm.loading
                  },
                  on: {
                    close: function close($event) {
                      _vm.selectedId = null
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }