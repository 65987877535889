var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        fullscreen: true,
        isDialogActive: _vm.isDialogActive,
        title: _vm.$t("sign.ReportDocumentSignRequestDialog.title"),
        leftText: _vm.$t("components.template.dialog.preview.back"),
        rightText: _vm.rightText,
        supressKeyboardActions: true,
        hideLeft: _vm.hideLeft,
        hideRight: _vm.hideRight,
        rightDisabled: !_vm.isValid || _vm.isLoading,
        leftLoading: _vm.isLoading,
        rightLoading: _vm.isLoading
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        leftClick: _vm.previous,
        rightClick: _vm.next,
        close: _vm.close
      }
    },
    [
      _c("report-document-sign-request-steps", {
        ref: "steps",
        attrs: {
          report: _vm.report,
          step: _vm.step,
          loading: _vm.isLoading,
          valid: _vm.isValid,
          fullscreen: true
        },
        on: {
          "update:step": function updateStep($event) {
            _vm.step = $event
          },
          "update:loading": function updateLoading($event) {
            _vm.isLoading = $event
          },
          "update:valid": function updateValid($event) {
            _vm.isValid = $event
          },
          close: _vm.close
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }