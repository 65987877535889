import { render, staticRenderFns } from "./PdvViewer.vue?vue&type=template&id=285b7fe9&"
import script from "./PdvViewer.vue?vue&type=script&lang=ts&"
export * from "./PdvViewer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VFadeTransition,VIcon,VImg,VRow,VSkeletonLoader,VSlideXTransition,VSlideYTransition,VSpacer})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Touch})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('285b7fe9')) {
      api.createRecord('285b7fe9', component.options)
    } else {
      api.reload('285b7fe9', component.options)
    }
    module.hot.accept("./PdvViewer.vue?vue&type=template&id=285b7fe9&", function () {
      api.rerender('285b7fe9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/files/v2/PdvViewer.vue"
export default component.exports